import React, { useState, useEffect } from "react";
import { Input, Modal, Form, message, Radio, Select, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../services/User";
export const EditRecord = ({
  title,
  isEditing,
  resetEditing,
  form,
  updateRecord,
  editData,
  formFields,
  formName,
  delRecord,
  metaData,
  refreshSession,
}) => {
  const [categoryId, setCategoryId] = useState("");
  const [entryTypeId, setEntryTypeId] = useState("");
  const [entryTypes, setEntryTypes] = useState([]);
  const [entryData, setEntryData] = useState({});
  const [locationId, setlocationId] = useState("");
  const [tillConfigs, setTillConfigs] = useState([]);

  const navigate = useNavigate();
  const [user, setUser] = useState("");

  useEffect(() => {
    const getUser = async () => {
      const data = await getUserData();
      if (data) {
        setUser({
          username: data.username,
          rolePrivilege: { ...data.rolePrivilege },
        });
      } else {
        navigate("/login");
      }
    };
    getUser();
    if (["deposit_slip", "till_entry"].includes(formName) && editData) {
      const entryTypeID = editData.entryType.id;
      const entries = formFields.filter((ff) => ff.dataTitle === "entryType")[0]
        .data;
      const categoryID = entries.filter((entry) => entry.id === entryTypeID)[0]
        .categoryType.id;
      const entryTypeSelected = entries.filter(
        (entry) => entry.categoryType.id === categoryID
      );
      setEntryTypeId(entryTypeID);
      setEntryTypes(entryTypeSelected);
      if (formName === "till_entry") {
        const locationID = editData.tillConfiguration.locationId;
        const tillConfig = formFields.filter(
          (ff) => ff.dataTitle === "tillIdentifier"
        )[0].data;
        const tillConfigOptions = tillConfig.filter(
          (till) => till.location.id === locationID
        );
        setTillConfigs(tillConfigOptions);
      }
    }
  }, [editData, formFields, formName, navigate]);
  useEffect(() => {
    if (["deposit_slip", "till_entry"].includes(formName) && entryTypeId) {
      const entries = formFields.filter((ff) => ff.dataTitle === "entryType")[0]
        .data;
      const selectedEntry = entries.filter((entry) => entry.id === entryTypeId);
      if (entries.length > 0 && selectedEntry.length > 0) {
        setEntryData(selectedEntry[0]);
      }
    }
  }, [entryTypeId, formFields, formName]);

  useEffect(() => {
    if (["deposit_slip", "till_entry"].includes(formName) && locationId) {
      form.setFieldsValue({ tillConfigurationId: "" });
      const tillConfigurations = formFields.filter(
        (ff) =>
          ff.dataTitle ===
          (formName === "deposit_slip" ? "name" : "tillIdentifier")
      )[0].data;
      if (tillConfigurations.length > 0) {
        if (formName === "deposit_slip") {
          setTillConfigs(
            tillConfigurations.filter((config) => config.id === locationId)
          );
        } else {
          setTillConfigs(
            tillConfigurations.filter(
              (config) => config.location.id === locationId
            )
          );
        }
      }
    }
  }, [locationId, formFields, formName, form]);

  useEffect(() => {
    if (
      ["deposit_slip", "till_entry"].includes(formName) &&
      categoryId !== ""
    ) {
      form.setFieldsValue({ entryTypeId: "" });
      setEntryTypeId("");
      const entries = formFields.filter((ff) => ff.dataTitle === "entryType")[0]
        .data;
      if (entries.length > 0) {
        setEntryTypes(
          entries.filter((entry) => entry.categoryType.id === categoryId)
        );
      }
    }
  }, [categoryId, formFields, formName, form]);
  const updateEncryptedData = async () => {
    try {
      // const { data } = await refreshSession();
      // await encryptData(data);
    } catch (error) {
      message.error("Something is wrong with credentials");
      localStorage.removeItem("User");
      localStorage.removeItem("Mac");
      localStorage.removeItem("Key");
    }
  };
  return (
    <Modal
      title={title}
      open={isEditing}
      okText="Save"
      maskClosable={false}
      onCancel={() => {
        resetEditing("cancel");
      }}
      onOk={async () => {
        try {
          let values = await form.validateFields();
          if (formName === "till_entry") {
            values.metadata = {};
            if (values?.chequeDate) {
              values.chequeDate = values.chequeDate.format("YYYY-MM-DD");
            }
            metaData.forEach((detail) => {
              if (values[detail]) {
                values.metadata[detail] = values[detail];
              }
              delete values[detail];
            });
          }
          if (
            ["deposit_slip", "till_entry"].includes(formName) &&
            values.categoryTypeId
          ) {
            delete values.categoryTypeId;
            delete values.locationId;
          }
          if (formFields.length === 1) {
            values.username = editData.username;
            values.status = editData.status;

            if (formFields[0].dataIndex === "location") {
              values.location = formFields[0].data.filter((ff) => {
                return ff.id === values.location;
              })[0];
              values.role = editData.role;
            }
          }

          if (values?.date) {
            values.date = values.date.format("YYYY-MM-DD");
          }

          if (Object.keys(values).includes("privilege")) {
            const role = values.role;
            const promise_arr = [];
            const delPrivileges = editData.privileges.filter(
              (privilege) => !values.privilege.includes(privilege)
            );
            if (delPrivileges.length > 0) {
              delPrivileges.forEach((privilege) => {
                promise_arr.push(delRecord({ role, privilege }));
              });
            }
            const addPrivileges = values.privilege.filter(
              (privilige) => !editData.privileges.includes(privilige)
            );
            if (addPrivileges.length > 0) {
              addPrivileges.forEach((privilege) => {
                promise_arr.push(updateRecord({ role, privilege }));
              });
            }
            if (promise_arr.length > 0) {
              try {
                await Promise.all(promise_arr);

                if (
                  values.role === user.rolePrivilege.role &&
                  !values.privilege.includes("CAN_ASSIGN_ROLES_AND_PRIVILEGES")
                ) {
                  navigate("/");
                }
                await updateEncryptedData();
                message.success("Record updated successfully.");
                resetEditing("ok");
              } catch (error) {
                if (error.code !== "ERR_NETWORK") {
                  message.error(error);
                }
              }
            } else {
              message.success("There is nothing to update.");
              resetEditing("ok");
            }
          } else {
            try {
              const res = await updateRecord(
                editData.id ? editData.id : editData._id,
                values
              );
              if (res?.status === 200) {
                message.success("Record updated successfully.");

                if (
                  title === "Edit User Role" &&
                  values.role === "ROLE_DATA_ENTRY" &&
                  values.username === user.username
                ) {
                  navigate("/");
                }
                if (formName === "user") {
                  await updateEncryptedData();
                }

                resetEditing("ok");
              }
            } catch (error) {
              if (error.code !== "ERR_NETWORK") {
                const { data } = error.response;
                message.error(data.message);
              }
            }
          }
        } catch (error) {
          message.error("Validate Failed:", error);
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name={formName ? formName : "edit_form"}
      >
        {formFields.map((field) => {
          const { title, dataIndex, type, require } = field;
          let hidden = false;
          if (metaData && metaData.includes(dataIndex)) {
            if (!entryTypeId || (entryTypeId && !entryData?.hasCheque)) {
              hidden = true;
            }
          }
          if (type === "dropdown") {
            const selectTitle = field.dataTitle;
            let selectData;

            if (["deposit_slip", "till_entry"].includes(formName)) {
              if (selectTitle === "entryPartition") {
                if (!entryTypeId || (entryTypeId && !entryData?.hasPartition)) {
                  hidden = true;
                }
              }
              if (selectTitle === "entryType") {
                selectData = entryTypes;
              } else if (selectTitle === "tillIdentifier") {
                selectData = tillConfigs;
              } else {
                selectData = field.data;
              }
            } else {
              selectData = field.data;
            }
            if (selectTitle === "array") {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  <Select>
                    {selectData.map((val, index) => {
                      return (
                        <Select.Option key={index} value={val}>
                          {val}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else if (selectTitle === "multiselect") {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  <Select mode="multiple" allowClear>
                    {selectData.map((val, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={val.value}
                          label={val.label}
                        >
                          {val.value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  hidden={hidden}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  {(selectTitle === "categoryType" ||
                    selectTitle === "entryType" ||
                    selectTitle === "name") &&
                  ["deposit_slip", "till_entry"].includes(formName) ? (
                    <Select
                      disabled={field.editDisable ? field.editDisable : false}
                      value={
                        selectTitle === "categoryType"
                          ? categoryId
                          : selectTitle === "entryType"
                          ? entryTypeId
                          : locationId
                      }
                      onSelect={(e) => {
                        if (selectTitle === "categoryType") {
                          setCategoryId(e);
                        } else if (selectTitle === "entryType") {
                          setEntryTypeId(e);
                        } else {
                          setlocationId(e);
                        }
                      }}
                    >
                      {selectData.map((val, index) => {
                        return (
                          <Select.Option key={index} value={val.id}>
                            {`${val[selectTitle]}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select>
                      {selectData.map((val, index) => {
                        return (
                          <Select.Option key={index} value={val.id}>
                            {`${val[selectTitle]}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              );
            }
          } else if (type === "radio") {
            return (
              <Form.Item
                label={title}
                key={dataIndex}
                name={dataIndex}
                rules={
                  require
                    ? [
                        {
                          required: true,
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <Radio.Group>
                  <Radio value="yes"> Yes </Radio>
                  <Radio value="no"> No </Radio>
                </Radio.Group>
              </Form.Item>
            );
          } else if (type === "date") {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                hidden={hidden}
                rules={
                  require
                    ? [
                        {
                          required: { require },
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            );
          } else if (type === "password") {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                hidden={hidden}
                initialValue={editData?.dataIndex}
                rules={
                  require
                    ? [
                        {
                          required: true,
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <Input />
              </Form.Item>
            );
          }
        })}
      </Form>
    </Modal>
  );
};
