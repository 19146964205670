import React, { useState, useEffect } from "react";
import { Modal, Table, message, Divider, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export const ModalTable = ({
  assignedTillEntries,
  bulkAssign,
  colData,
  depositId,
  resetAdding,
  tillAdding,
  unassignedTillEntries,
  updateTableRecs,
  cookies,
}) => {
  const [allTillEntries, setAllTillEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setLoading(true);
    for (let i = 0; i < colData.length; i++) {
      if (["Amount", "Deposit Number"].includes(colData[i]["title"])) {
        colData[i]["render"] = (record) => {
          const amountFormated = new Intl.NumberFormat(cookies["Currency"][1], {
            style: "currency",
            currency: cookies["Currency"][0],
          }).format(record);
          return amountFormated;
        };
      }
    }
  }, [cookies, colData]);
  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    const getAssignedTills = async () => {
      try {
        const res = await assignedTillEntries(depositId);
        if (res?.status === 200) {
          setSelectedRows([...res.data]);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
    };
    const getUnassignedTills = async () => {
      try {
        const res = await unassignedTillEntries();
        if (res?.status === 200) {
          setAllTillEntries(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
    };
    setLoading(true);
    getAssignedTills();
    getUnassignedTills();

    setReload(false);
  }, [reload, unassignedTillEntries, assignedTillEntries, depositId]);

  const rowSelections = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowId(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to unassign this Till Entry?",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        const upd_data = {
          id: record.id,
          depositSlipId: null,
          tillConfigurationId: record.tillConfiguration.id,
          entryPartitionId: record.entryPartition?.id,
          entryTypeId: record.entryType.id,
          date: `${record.date[0]}-${record.date[1]
            .toString()
            .padStart(2, 0)}-${record.date[2].toString().padStart(2, 0)}`,
          entryAmount: record.entryAmount,
          modifiedBy: record.modifiedBy,
          metadata: {},
        };
        try {
          const res = await updateTableRecs(record.id, upd_data);
          if (res?.status === 200) {
            message.success(
              "Deposit Slip unassigned to the selected Till Entries."
            );
          }
          setReload(true);
        } catch (error) {
          if (error.code !== "ERR_NETWORK") {
            const { data } = error.response;

            message.error(data.message);
          }
        }
      },
    });
  };

  return (
    <Modal
      open={tillAdding}
      width={"900px"}
      okText="Save"
      maskClosable={false}
      onCancel={() => {
        setSelectedRowId([]);
        resetAdding("cancel");
      }}
      onOk={async () => {
        try {
          const res = await bulkAssign({
            depositSlipId: depositId,
            tillEntries: selectedRowId,
          });
          if (res?.status === 200) {
            message.success(
              "Deposit Slip assigned to the selected Till Entries."
            );
          }
          resetAdding("ok");
        } catch (error) {
          if (error.code !== "ERR_NETWORK") {
            message.error(error.message);
          }
        }
      }}
    >
      <Typography.Title level={4}>Assigned Till Entries</Typography.Title>
      <Table
        loading={loading}
        columns={[
          ...colData,
          {
            key: "action",
            title: "Unassign Action",
            render: (record) => {
              return (
                <>
                  <DeleteOutlined
                    onClick={() => {
                      onDeleteRecord(record);
                    }}
                    style={{ color: "red", marginLeft: 12 }}
                  />
                </>
              );
            },
          },
        ]}
        dataSource={selectedRows}
        rowKey="id"
      />
      <Divider />
      <Typography.Title level={4}>Add Till Entries</Typography.Title>
      <Table
        loading={loading}
        rowSelection={{
          type: "checkbox",
          ...rowSelections,
        }}
        columns={colData}
        dataSource={allTillEntries}
        rowKey="id"
      />
    </Modal>
  );
};
