import React from "react";
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";
import { Result } from "antd";

const COLORS = [
  "#00C49F",
  "#7f79f3",
  "#0088FE",
  "#FF8042",
  "#FFBB28",
  "#82ca9d",
];

export const PieChartGraph = ({ data, cookies }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const amount = Intl.NumberFormat(cookies["Currency"][1], {
        style: "currency",
        currency: cookies["Currency"][0],
      }).format(payload[0]["value"]);
      const name = payload[0]["name"];

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "1px 10px 1px 10px" }}
        >
          <p>
            {name}: <b>{amount}</b>
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <div style={{ marginTop: 98 }}>
      {data.length > 0 ? (
        <PieChart width={360} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>

          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      ) : (
        <Result title="There is no data to show in the pie-chart." />
      )}
    </div>
  );
};
