import React, { useState, useEffect } from "react";
import { Input, Modal, Form, message, Radio, Select, DatePicker } from "antd";

export const AddRecord = ({
  title,
  isAdding,
  resetAdding,
  form,
  addRecord,
  formFields,
  formName,
}) => {
  const [categoryId, setCategoryId] = useState("");
  const [entryTypes, setEntryTypes] = useState([]);

  useEffect(() => {
    if (["deposit_slip"].includes(formName) && categoryId !== "") {
      form.setFieldsValue({ entryTypeId: "" });
      const entries = formFields.filter((ff) => ff.dataTitle === "entryType")[0]
        .data;
      if (entries.length > 0) {
        setEntryTypes(
          entries.filter((entry) => entry.categoryType.id === categoryId)
        );
      }
    }
  }, [categoryId, formFields, formName, form]);
  return (
    <Modal
      title={title}
      open={isAdding}
      okText="Save"
      maskClosable={false}
      onCancel={() => {
        resetAdding("cancel");
      }}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          if (formName === "till_entry" && !values.entryPartitionId) {
            delete values.entryPartitionId;
          }
          if (["deposit_slip"].includes(formName) && values.categoryTypeId) {
            delete values.categoryTypeId;
          }

          if (values?.date) {
            values.date = values.date.format("YYYY-MM-DD");
          }
          if (Object.keys(values).includes("privilege")) {
            let role = values.role;
            Promise.all(
              values.privilege.map((privilege) => {
                return addRecord({ role, privilege });
              })
            )
              .then((res) => {
                message.success("Record created successfully.");
                resetAdding("ok");
              })
              .catch((error) => {
                if (error.code !== "ERR_NETWORK") {
                  const { data } = error.response;
                  message.error(data.message);
                }
              });
          } else {
            try {
              const res = await addRecord(values);
              if (res?.status) {
                message.success("Record created successfully.");
                resetAdding("ok");
              }
            } catch (error) {
              if (error.code !== "ERR_NETWORK") {
                const { data } = error.response;
                message.error(data.message);
              }
            }
          }
        } catch (error) {
          if (error.message) {
            message.error("Validate Failed: ", error);
          }
        }
      }}
    >
      <Form form={form} layout="vertical" name="add_form">
        {formFields.map((field) => {
          const { title, dataIndex, type, require } = field;
          if (type === "dropdown") {
            const selectTitle = field.dataTitle;
            const selectData =
              selectTitle === "entryType" && ["deposit_slip"].includes(formName)
                ? entryTypes
                : field.data;
            if (selectTitle === "array") {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  <Select>
                    {selectData.map((val, index) => {
                      return (
                        <Select.Option key={index} value={val}>
                          {val}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else if (selectTitle === "multiselect") {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  <Select mode="multiple" allowClear>
                    {selectData.map((val, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={val.value}
                          label={val.label}
                        >
                          {val.value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  {selectTitle === "categoryType" &&
                  ["deposit_slip", "till_entry"].includes(formName) ? (
                    <Select
                      value={categoryId}
                      onSelect={(e) => {
                        setCategoryId(e);
                      }}
                    >
                      {selectData.map((val, index) => {
                        return (
                          <Select.Option key={index} value={val.id}>
                            {`${val[selectTitle]}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select>
                      {selectData.map((val, index) => {
                        return (
                          <Select.Option key={index} value={val.id}>
                            {`${val[selectTitle]}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              );
            }
          } else if (type === "radio") {
            return (
              <Form.Item
                label={title}
                key={dataIndex}
                name={dataIndex}
                rules={
                  require
                    ? [
                        {
                          required: { require },
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <Radio.Group>
                  <Radio value="yes"> Yes </Radio>
                  <Radio value="no"> No </Radio>
                </Radio.Group>
              </Form.Item>
            );
          } else if (type === "date") {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                rules={
                  require
                    ? [
                        {
                          required: { require },
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            );
          } else if (type === "password") {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                rules={
                  require
                    ? [
                        {
                          required: { require },
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <Input />
              </Form.Item>
            );
          }
        })}
      </Form>
    </Modal>
  );
};
