import React, { useState, useEffect } from "react";
import { Button, Typography, Form, message } from "antd";
import {
  createRolePrivilege,
  deleteRolePrivilege,
  getAllRolePrivileges,
  updateRolePrivilege,
  getRolePrivileges,
  getRoleTypes,
  getRolePrivilegeByRole,
} from "../services/RolePrivilege";
import { refreshSession } from "../services/User";
import { AddRecord } from "../Components/AddRecord";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";
export const RolePrivilege = ({ userInfo, reloading }) => {
  const [colData, setColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [allRolePrivileges, setAllRolePrivileges] = useState([]);
  const [allRoleTypes, setAllRoleTypes] = useState([]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        let res = await getRoleTypes();
        if (res?.status === 200) {
          setAllRoleTypes(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        let res = await getRolePrivileges();
        if (res?.status === 200) {
          setAllRolePrivileges(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        let res = await getAllRolePrivileges();
        if (res?.status === 200) {
          setDataSource(
            res.data.map((dt, index) => {
              dt.id = index + 1;
              return dt;
            })
          );
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }

      setColData([
        {
          title: "Role",
          dataIndex: "role",
        },
        {
          title: "Privileges",
          dataIndex: "privileges",
          render: (record) => {
            return record.join(", ");
          },
        },
      ]);

      setReload(false);
      setLoading(false);
    };
    getData();
  }, [reload]);

  useEffect(() => {
    setFormFields([
      {
        title: "Role",
        dataIndex: "role",
        type: "dropdown",
        require: true,
        data: allRoleTypes,
        dataTitle: "array",
      },
      {
        title: "Privileges",
        dataIndex: "privilege",
        type: "dropdown",
        require: true,
        data: allRolePrivileges.map((privileges) => {
          return { lable: privileges, value: privileges };
        }),
        dataTitle: "multiselect",
      },
    ]);
  }, [allRolePrivileges, allRoleTypes]);

  const onAddRecord = () => {
    setIsAdding(true);
    let fieldsValue = formFields.reduce((fieldValues, field) => {
      fieldValues[field.dataIndex] =
        field.dataTitle === "multiselect" ? [] : "";
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setSelectedRecord(null);
    if (event !== "cancel") {
      setReload(true);
      reloading(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4}>Role Details</Typography.Title>
        <Button
          type="primary"
          onClick={() => {
            onAddRecord();
          }}
        >
          Add
        </Button>
      </div>

      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        deleteRecord={deleteRolePrivilege}
        form={form}
        formName={"role_privilege"}
        setIsEditing={[setIsEditing]}
        editingRecord={setSelectedRecord}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getRolePrivilegeByRole}
      />

      <EditRecord
        title="Edit Role"
        isEditing={isEditing}
        resetEditing={resetEditing}
        form={form}
        updateRecord={updateRolePrivilege}
        editData={selectedRecord}
        formFields={formFields}
        delRecord={deleteRolePrivilege}
        refreshSession={refreshSession}
      />
      <AddRecord
        title="Add Role"
        isAdding={isAdding}
        resetAdding={resetAdding}
        form={form}
        addRecord={createRolePrivilege}
        formFields={formFields}
        formName={"role_privilege"}
      />
    </div>
  );
};
