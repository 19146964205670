import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};

// Reports Apis

export const getAllReports = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/report`, config);
};

export const getAllReportsByDateRange = async (startDate, endDate) => {
  const user = await getUserData();

  return axios.get(
    `${
      process.env.REACT_APP_.BASE_URL
    }/report/search?startingDate=${startDate}&endingDate=${endDate}${
      user.location ? `&location=${user.location.id}` : ""
    }`,
    config
  );
};

export const getAllReportsByLocation = async (locId) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/report/location/${locId}`,
    config
  );
};

export const getAllReportsForExportSearch = async (startDate, endDate) => {
  const user = await getUserData();
  return axios.get(
    `${
      process.env.REACT_APP_.BASE_URL
    }/report/export-search?startingDate=${startDate}&endingDate=${endDate}${
      user.location ? `&location=${user.location.id}` : ""
    }`,
    { ...config, responseType: "blob" }
  );
};

// Reports Apis
