import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// Till Confirguration Apis
export const getAllTillConfigurations = async () => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-configuration`,
    config
  );
};
export const getTillConfigurationById = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-configuration/${id}`,
    config
  );
};
export const getTillConfigByLoc = async (loc_id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-configuration/location/${loc_id}`,
    config
  );
};
export const createTillConfiguration = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/till-configuration`,
    values,
    config
  );
};
export const deleteTillConfiguration = async (config_id) => {
  return axios.delete(
    `${process.env.REACT_APP_.BASE_URL}/till-configuration/${config_id}`,
    config
  );
};
export const updateTillConfiguration = async (id, upd_data) => {
  const user = await getUserData();
  upd_data.modifiedBy = user.username;
  upd_data.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/till-configuration`,
    {
      values: upd_data,
    },
    config
  );
};
// Till Configuration Apis
