import React, { useState, useEffect } from "react";
import { Table, message, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EnvironmentFilled,
  UserSwitchOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

export const TableView = ({
  loading,
  dataSource,
  rkey,
  columnsData,
  deleteRecord,
  form,
  formName,
  setIsEditing,
  editingRecord,
  setReload,
  formFields,
  editEnable,
  delEnable,
  getSelectedRecord,
  metaData,
  cookies,
}) => {
  const [relod, setRelod] = useState(false);

  useEffect(() => {
    setRelod(true);

    for (let i = 0; i < columnsData.length; i++) {
      if (["Amount", "Deposit Number"].includes(columnsData[i]["title"])) {
        columnsData[i]["render"] = (record) => {
          const amountFormated = new Intl.NumberFormat(cookies["Currency"][1], {
            style: "currency",
            currency: cookies["Currency"][0],
          }).format(record);
          return amountFormated;
        };
      }
    }
  }, [cookies, columnsData]);
  useEffect(() => {
    if (relod) {
      setRelod(false);
    }
  }, [relod]);

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        try {
          const res = await deleteRecord(
            rkey === "id" ? record.id : record._id
          );
          if (res?.status === 200) {
            message.success("Record has been deleted successfully.");
          }
          setReload(true);
        } catch (error) {
          if (error.code !== "ERR_NETWORK") {
            const { data } = error.response;

            message.error(data.message);
          }
        }
      },
    });
  };
  const onEditRecord = async (record) => {
    let recordId = record.id;
    if (formName === "role_privilege") {
      recordId = record.role;
    } else if (formName === "user") {
      recordId = record.username;
    }
    try {
      const res = await getSelectedRecord(recordId);
      if (res?.status === 200) {
        editingRecord({ ...res.data });
      }
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        const { data } = error.response;

        message.error(data.message);
      }
      return;
    }
    Array.isArray(setIsEditing) ? setIsEditing[0](true) : setIsEditing(true);
    // editingRecord({ ...record });
    const fieldsValue = formFields
      .filter((ff) => ff.dataIndex !== "password")
      .reduce((fieldValues, field) => {
        const specificFrom = ["deposit_slip", "till_entry"].includes(formName);
        let entries;
        if (specificFrom && metaData && metaData.includes(field.dataIndex)) {
          const value = record.metadata[field.dataIndex];
          if (field.type === "date") {
            fieldValues[field.dataIndex] = value ? dayjs(value) : "";
          } else {
            fieldValues[field.dataIndex] = value;
          }
        } else {
          if (specificFrom) {
            entries = formFields.filter(
              (ff) => ff.dataIndex === "entryTypeId"
            )[0].data;
          }
          const val =
            field.dataIndex === "categoryTypeId" && specificFrom
              ? entries.filter((entry) => entry.id === record.entryType.id)[0]
                  .categoryType.id
              : field.dataIndex === "categoryTypeId"
              ? record.categoryType.id
              : field.dataIndex === "entryTypeId"
              ? record.entryType.id
              : field.dataIndex === "locationId" && formName === "till_entry"
              ? record.tillConfiguration.locationId
              : field.dataIndex === "locationId" && formName === "deposit_slip"
              ? record.locationId
              : field.dataIndex === "locationId" ||
                field.dataIndex === "location"
              ? record.location?.id
              : field.dataIndex === "tillConfigurationId"
              ? record.tillConfiguration.id
              : field.dataIndex === "entryPartitionId"
              ? record.entryPartition?.id
              : field.dataIndex === "privilege"
              ? record.privileges
              : record[field.dataIndex];
          if (typeof val === "boolean") {
            fieldValues[field.dataIndex] = val ? "yes" : "no";
          } else if (field.type === "date") {
            fieldValues[field.dataIndex] = val ? dayjs(val) : "";
          } else {
            fieldValues[field.dataIndex] = val;
          }
        }
        return fieldValues;
      }, {});
    form.setFieldsValue(fieldsValue);
  };
  const onEditRole = async (record) => {
    try {
      const res = await getSelectedRecord(record.username);
      if (res?.status === 200) {
        editingRecord({ ...res.data });
      }
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        const { data } = error.response;

        message.error(data.message);
      }
      return;
    }
    Array.isArray(setIsEditing) ? setIsEditing[1](true) : setIsEditing(true);
    const fieldsValue = { role: record.rolePrivilege.role };
    form.setFieldsValue(fieldsValue);
  };
  return (
    <Table
      loading={loading || relod}
      columns={[
        ...columnsData,
        {
          key: "action",
          title: "Actions",
          render: (record) => {
            return (
              <>
                {formName !== "user" && editEnable && (
                  <EditOutlined
                    onClick={() => {
                      onEditRecord(record);
                    }}
                  />
                )}

                {formName === "user" && editEnable && (
                  <>
                    <EnvironmentFilled
                      onClick={() => {
                        onEditRecord(record);
                      }}
                      style={{ fontSize: 16 }}
                    />
                    <UserSwitchOutlined
                      onClick={() => {
                        onEditRole(record);
                      }}
                      style={{ fontSize: 16, marginLeft: 12 }}
                    />
                  </>
                )}
                {formName !== "user" &&
                  formName !== "role_privilege" &&
                  delEnable && (
                    <DeleteOutlined
                      onClick={() => {
                        onDeleteRecord(record);
                      }}
                      style={{ color: "red", marginLeft: 12 }}
                    />
                  )}
              </>
            );
          },
        },
      ]}
      dataSource={dataSource}
      pagination={{
        pageSize: 8,
      }}
      rowKey={rkey || "id"}
    ></Table>
  );
};
