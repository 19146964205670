import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LogoutOutlined,
  SettingOutlined,
  ShopOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  AppstoreOutlined,
  FileExcelOutlined,
  ProfileOutlined,
  UsergroupAddOutlined,
  EnvironmentOutlined,
  AuditOutlined,
  PartitionOutlined,
  GoldOutlined,
  ControlOutlined,
} from "@ant-design/icons";

import {
  Badge,
  Space,
  Typography,
  message,
  Dropdown,
  Avatar,
  Form,
  Select,
  Button,
  Drawer,
  Menu,
} from "antd";

import { useCookies } from "react-cookie";

export const Header = ({ userDetails, cookies, setCookie }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState("/");

  const menuItems = [
    {
      label: "Dashboard",
      icon: <AppstoreOutlined />,
      key: "/",
    },
    {
      label: "Till Entry",
      key: "/till-entry",
      icon: <ShopOutlined />,
    },
    {
      label: "Deposit Slip",
      key: "/deposit-slip",
      icon: <ProfileOutlined />,
    },
    {
      label: "Report",
      key: "/report",
      icon: <FileExcelOutlined />,
    },
  ];

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
  }, [location.pathname]);

  const [userRole, setUserRole] = useState("");
  const [userName, setUserName] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [session, setSession, removeSession] = useCookies(["SESSION"]);
  const currencies = {
    MWK: "mwk",
    USD: "en-US",
    EUR: "en-DE",
    GBP: "en-GB",
    JPY: "ja-JP",
  };
  const items = [
    {
      label: "User",
      icon: <UserOutlined style={{ color: "#2370f4" }} />,
      key: "/user",
      onClick: () => navigate("/user"),
    },
    {
      label: "Role",
      icon: <UsergroupAddOutlined style={{ color: "#2370f4" }} />,
      key: "/role",
      onClick: () => navigate("/role"),
    },
    {
      label: "Category",
      key: "/category",
      icon: <GoldOutlined style={{ color: "#2370f4" }} />,
      onClick: () => navigate("/category"),
    },
    {
      label: "Entry",
      key: "/entry",
      icon: <AuditOutlined style={{ color: "#2370f4" }} />,
      onClick: () => navigate("/entry"),
    },
    {
      label: "Entry Partition",
      key: "/entry-partition",
      icon: <PartitionOutlined style={{ color: "#2370f4" }} />,
      onClick: () => navigate("/entry-partition"),
    },
    {
      label: "Location",
      key: "/location",
      icon: <EnvironmentOutlined style={{ color: "#2370f4" }} />,
      onClick: () => navigate("/location"),
    },
    {
      label: "Till Configuration",
      key: "/till-config",
      icon: <ControlOutlined style={{ color: "#2370f4" }} />,
      onClick: () => navigate("/till-config"),
    },
    {
      label: "Log Out",
      key: "/",
      icon: <LogoutOutlined style={{ rotate: "270deg", color: "#2370f4" }} />,
      onClick: () => logoutHandler(),
    },
  ];
  const handleChange = (value) => {
    setCookie("Currency", [value, currencies[value]]);
  };
  const filterItems = (items) => {
    let filteredItem = [...items];
    const role = userDetails?.rolePrivilege?.role;
    const priviliges = userDetails?.rolePrivilege?.privileges;
    const configuration = [
      "Category",
      "Entry",
      "Entry Partition",
      "Location",
      "Till Configuration",
    ];
    if (!["ROLE_ADMIN", "ROLE_DEVELOPER"].includes(role)) {
      filteredItem = filteredItem.filter((items) => items.label !== "User");
    }
    if (!priviliges?.includes("CAN_ASSIGN_ROLES_AND_PRIVILEGES")) {
      filteredItem = filteredItem.filter((items) => items.label !== "Role");
    }
    if (!priviliges?.includes("CAN_CHANGE_CONFIGURATION")) {
      filteredItem = filteredItem.filter(
        (items) => !configuration.includes(items.label)
      );
    }
    return filteredItem;
  };
  useEffect(() => {
    const role = userDetails?.rolePrivilege?.role;
    const username = userDetails?.username?.toUpperCase();
    if (!userName || userName !== username) {
      setUserName(username);
    }
    if (
      !userRole ||
      userRole !==
        `(${role?.toUpperCase().replace("ROLE_", "").replaceAll("_", " ")})`
    ) {
      setUserRole(
        `(${role?.toUpperCase().replace("ROLE_", "").replaceAll("_", " ")})`
      );
    }

    if (cookies["Currency"]) {
      form.setFieldsValue({
        currency: cookies["Currency"][0],
      });
    }
  }, [userDetails, cookies]);
  const logoutHandler = () => {
    removeSession("SESSION");
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <>
      <div className="headerMenu">
        <div className="AppHeader" direction="vertical">
          <Space>
            <Avatar size={"large"} icon={<UserOutlined />} />
            <Space
              direction="vertical"
              size={"small"}
              style={{ display: "flex" }}
            >
              <Typography.Text>{userName}</Typography.Text>
              <Typography.Text style={{ fontSize: 10 }}>
                {userRole}
              </Typography.Text>
            </Space>
          </Space>

          <Typography.Title>Expense Management System</Typography.Title>

          <Space>
            <Form form={form} style={{ marginTop: "28px" }}>
              <Form.Item name="currency" label="Currency">
                <Select
                  onChange={handleChange}
                  placeholder="Select the Currency"
                >
                  {Object.keys(currencies).map((val, index) => {
                    return (
                      <Select.Option key={index} value={val}>
                        {val}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
            <Badge count={0}>
              <Dropdown
                menu={{ items: filterItems(items) }}
                placement="topRight"
                arrow
              >
                <a href="/" onClick={(e) => e.preventDefault()}>
                  <Space>
                    <SettingOutlined style={{ fontSize: 24 }} />
                  </Space>
                </a>
              </Dropdown>
            </Badge>
          </Space>
        </div>
      </div>
      {/* <div className="menuIcon"> */}
      <div
        className="AppHeader menuIcon"
        style={{
          flexDirection: "column",
          padding: "0px",
          height: "82px",
          alignItems: "normal",
        }}
      >
        <div className="tabletDesign">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-evenly",
              // paddingLeft: "15px",
              // paddingRight: "15px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setOpenMenu(true);
              }}
              style={{
                // marginLeft: 10,
                fontSize: "20px",
                padding: "7px 10px 0px 15px",
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              }}
            >
              {/* {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} */}
              {/* {collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />} */}
              <MenuUnfoldOutlined />
            </Button>
            <Typography.Title
              style={{
                fontSize: "28px",
                margin: "7px",
                alignSelf: "center",
                // marginLeft: "30px",
              }}
            >
              Expense Management System
            </Typography.Title>
          </div>
        </div>

        <div className="mobileDesign">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-around",
              // paddingLeft: "15px",
              // paddingRight: "15px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setOpenMenu(true);
              }}
              style={{
                // marginLeft: 10,
                padding: "9px 0px 0px 7px",
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              }}
            >
              {/* {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} */}
              {/* {collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />} */}
              <MenuUnfoldOutlined />
            </Button>
            <Typography.Title
              style={{ fontSize: "20px", margin: "8px", alignSelf: "start" }}
            >
              Expense Management System
            </Typography.Title>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Form
            form={form}
            style={{
              marginTop: "0px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <Form.Item
              name="currency"
              label="Currency"
              style={{ display: "flex", flexDirection: "row" }}
            > */}
            <label
              htmlFor="Currency"
              style={{
                marginTop: "5px",
                marginRight: "8px",
                marginBottom: "7px",
              }}
            >
              Currency:
            </label>
            <Select
              onChange={handleChange}
              placeholder="Select the Currency"
              style={{ padding: "2px" }}
            >
              {Object.keys(currencies).map((val, index) => {
                return (
                  <Select.Option key={index} value={val}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
            {/* </Form.Item> */}
          </Form>
          <Badge style={{ alignSelf: "center" }}>
            <Dropdown
              menu={{ items: filterItems(items) }}
              placement="bottomRight"
              arrow
              style={{ alignSelf: "center" }}
            >
              <a
                href="/"
                onClick={(e) => e.preventDefault()}
                style={{ alignSelf: "center" }}
              >
                <Space style={{ alignSelf: "center" }}>
                  <SettingOutlined style={{ fontSize: 21, marginTop: "5px" }} />
                </Space>
              </a>
            </Dropdown>
          </Badge>
        </div>
      </div>
      <Drawer
        placement="left"
        open={openMenu}
        onClose={() => {
          setOpenMenu(false);
        }}
        closable={false}
        // style={{ backgroundColor: "whitesmoke", padding: "0px" }}
        width="250px"
        title={
          <Space>
            <Avatar size={"large"} icon={<UserOutlined />} />
            <Space
              direction="vertical"
              size={"small"}
              style={{
                display: "flex",

                rowGap: "0px",
              }}
            >
              <Typography.Text style={{ fontSize: 17 }}>
                {userName}
              </Typography.Text>
              <Typography.Text style={{ fontSize: 12 }}>
                {userRole}
              </Typography.Text>
            </Space>
          </Space>
        }
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <MenuFoldOutlined />
            </Button>
          </Space>
        }
      >
        <Menu
          // style={{ backgroundColor: "black", color: "white" }}
          // inlineCollapsed={collapsed}
          className="SideMenuVertical"
          // mode="vertical"
          onClick={(item) => {
            navigate(item.key);
          }}
          selectedKeys={[selectedKeys]}
          items={menuItems.filter((menu) => {
            let privileges = userDetails?.rolePrivilege?.privileges;
            if (
              !privileges?.includes("CAN_VIEW_REPORTS") &&
              menu.label === "Report"
            ) {
              return false;
            } else {
              return true;
            }
          })}
        ></Menu>
      </Drawer>
    </>
  );
};
