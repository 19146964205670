import React, { useState, useEffect } from "react";
import { Button, Typography, Form, message } from "antd";

import {
  createEntryPartition,
  deleteEntryPartition,
  getAllEntriesPartition,
  updateEntryPartition,
  getEntryPartitionById,
} from "../services/EntryPartition";

import { AddRecord } from "../Components/AddRecord";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";
export const EntryPartition = ({ userInfo }) => {
  const [colData, setColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        const res = await getAllEntriesPartition();
        if (res?.status === 200) {
          setDataSource(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }

      setColData([
        {
          title: "Entry Partition",
          dataIndex: "entryPartition",
        },
        {
          title: "Modified By",
          dataIndex: "modifiedBy",
        },
      ]);
      setFormFields([
        {
          title: "Entry Partition",
          dataIndex: "entryPartition",
          type: "text",
          require: true,
        },
      ]);

      setReload(false);
      setLoading(false);
    };
    getData();
  }, [reload]);

  const onAddRecord = () => {
    setIsAdding(true);
    const fieldsValue = formFields.reduce((fieldValues, field) => {
      fieldValues[field.dataIndex] = "";
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setSelectedRecord(null);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };

  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4}>Entry Partition Details</Typography.Title>
        <Button
          type="primary"
          onClick={() => {
            onAddRecord();
          }}
        >
          Add
        </Button>
      </div>
      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        deleteRecord={deleteEntryPartition}
        form={form}
        formName={"entry_partition"}
        setIsEditing={[setIsEditing]}
        editingRecord={setSelectedRecord}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getEntryPartitionById}
      />

      <EditRecord
        title="Edit Entry Partition"
        isEditing={isEditing}
        resetEditing={resetEditing}
        form={form}
        updateRecord={updateEntryPartition}
        editData={selectedRecord}
        formFields={formFields}
      />
      <AddRecord
        title="Add Entry Partition"
        isAdding={isAdding}
        resetAdding={resetAdding}
        form={form}
        addRecord={createEntryPartition}
        formFields={formFields}
        formName={"entry_partition"}
      />
    </div>
  );
};
