import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// Till Location Apis
export const getAllLocations = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/location`, config);
};
export const getLocationById = async (id) => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/location/${id}`, config);
};
export const createLocation = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/location`,
    values,
    config
  );
};
export const deleteLocation = async (loc_id) => {
  return axios.delete(
    `${process.env.REACT_APP_.BASE_URL}/location/${loc_id}`,
    config
  );
};
export const updateLocation = async (id, upd_data) => {
  const user = await getUserData();
  upd_data.modifiedBy = user.username;
  upd_data.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/location`,
    upd_data,
    config
  );
};
// Till Location Apis
