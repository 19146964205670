import React, { useState, useEffect } from "react";
import { Button, Typography, Form, message, Switch } from "antd";
import { getAllLocations } from "../services/TillLocation";
import {
  getAllUsers,
  createUser,
  updateUser,
  updateUserRole,
  updateUserStatus,
  getUserByUsername,
  refreshSession,
} from "../services/User";
import { AddRecord } from "../Components/AddRecord";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";

export const User = ({ userInfo, reloading }) => {
  const [colData, setColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingRole, setIsEditingRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [allTillLocation, setAllTillLocation] = useState([]);
  const [editFormFields, setEditFormFields] = useState([]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        let res = await getAllUsers();
        if (res?.status === 200) {
          setDataSource(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        let res = await getAllLocations();
        if (res?.status === 200) {
          setAllTillLocation(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      setColData([
        {
          title: "User Name",
          dataIndex: "username",
        },
        {
          title: "Role",
          dataIndex: "rolePrivilege",
          render: (record) => {
            return record?.role;
          },
        },
        {
          title: "Location",
          dataIndex: "location",
          render: (record) => {
            return record?.name;
          },
        },
        {
          title: "Active",
          key: "active",
          render: (record) => {
            return (
              <>
                <Switch
                  defaultChecked={record.status === "ENABLED" ? true : false}
                  onChange={async (e) => {
                    try {
                      let res = await updateUserStatus({
                        username: record.username,
                        status: `${e ? "ENABLED" : "DISABLED"}`,
                      });
                      if (res?.status === 200) {
                        setReload(true);
                      }
                    } catch (error) {
                      if (error.code !== "ERR_NETWORK") {
                        message.error(error.message);
                      }
                    }
                  }}
                />
              </>
            );
          },
        },
        {
          title: "Modified By",
          dataIndex: "modifiedBy",
        },
      ]);
      setReload(false);
      setLoading(false);
    };

    getData();
  }, [reload]);

  useEffect(() => {
    setFormFields([
      {
        title: "User Name",
        dataIndex: "username",
        type: "text",
        require: true,
      },
      {
        title: "Password",
        dataIndex: "password",
        type: "password",
        require: true,
      },
      {
        title: "Role",
        dataIndex: "role",
        type: "dropdown",
        require: true,
        data: ["ROLE_ADMIN", "ROLE_DEVELOPER", "ROLE_DATA_ENTRY"],
        dataTitle: "array",
      },
      {
        title: "Location",
        dataIndex: "location",
        type: "dropdown",
        require: true,
        data: allTillLocation,
        dataTitle: "name",
      },
    ]);
    setEditFormFields([
      {
        title: "Location",
        dataIndex: "location",
        type: "dropdown",
        require: true,
        data: allTillLocation,
        dataTitle: "name",
      },
      {
        title: "Role",
        dataIndex: "role",
        type: "dropdown",
        require: true,
        data: ["ROLE_ADMIN", "ROLE_DEVELOPER", "ROLE_DATA_ENTRY"],
        dataTitle: "array",
      },
    ]);
  }, [allTillLocation]);

  const onAddRecord = () => {
    setIsAdding(true);
    let fieldsValue = formFields.reduce((fieldValues, field) => {
      fieldValues[field.dataIndex] = "";
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditingRole = (event) => {
    setIsEditingRole(false);
    setEditingUser(null);
    if (event !== "cancel") {
      setReload(true);
      reloading(true);
    }
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setEditingUser(null);
    if (event !== "cancel") {
      setReload(true);
      reloading(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };

  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4} style={{ display: "contents" }}>
          User Details
        </Typography.Title>
        <Button
          type="primary"
          onClick={() => {
            onAddRecord();
          }}
        >
          Add
        </Button>
      </div>

      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        form={form}
        formName={"user"}
        setIsEditing={[setIsEditing, setIsEditingRole]}
        editingRecord={setEditingUser}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getUserByUsername}
      />

      {isEditing && (
        <EditRecord
          title="Edit User Location"
          isEditing={isEditing}
          resetEditing={resetEditing}
          form={form}
          updateRecord={updateUser}
          editData={editingUser}
          formFields={editFormFields.slice(0, 1)}
          formName="user"
          refreshSession={refreshSession}
        />
      )}

      {isEditingRole && (
        <EditRecord
          title="Edit User Role"
          isEditing={isEditingRole}
          resetEditing={resetEditingRole}
          form={form}
          updateRecord={updateUserRole}
          editData={editingUser}
          formFields={editFormFields.slice(1)}
          formName="user"
          refreshSession={refreshSession}
        />
      )}

      <AddRecord
        title="Add User"
        isAdding={isAdding}
        resetAdding={resetAdding}
        form={form}
        addRecord={createUser}
        formFields={formFields}
        formName={"user"}
      />
    </div>
  );
};
