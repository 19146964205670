import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
export const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// User Apis
export const getAllUsers = async () => {
  const user = await getUserData();
  config.headers.Authorization = `Bearer ${user?.accessToken}`;
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/user`, config);
};

export const getUserByUsername = async (username) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/user/${username}`,
    config
  );
};

export const createUser = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/user/signup`,
    values,
    config
  );
};

export const refreshSession = async () => {
  const user = await getUserData();
  const values = {};
  config.headers.Authorization = `Bearer ${user?.accessToken}`;
  values.username = user.username;
  values.refreshToken = user.refreshToken;
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/user/refresh-token/`,
    values,
    config
  );
};

export const loginUser = (values) => {
  return axios.post(`${process.env.REACT_APP_.BASE_URL}/login`, values, config);
};

export const updateUser = async (id, upd_data) => {
  const user = await getUserData();
  upd_data.modifiedBy = user.username;
  upd_data.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/user/update-location`,
    upd_data,
    config
  );
};

export const updateUserStatus = async (upd_data) => {
  const user = await getUserData();

  upd_data.modifiedBy = user.username;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/user/update-status`,
    upd_data,
    config
  );
};

export const updateUserRole = async (id, upd_data) => {
  const user = await getUserData();

  upd_data.modifiedBy = user.username;

  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/user/assign-role`,
    upd_data,
    config
  );
};

export const logoutUser = async () => {
  const user = await getUserData();
  const newConfig = {
    headers: {
      Authorization: `Bearer ${user?.accessToken}`,
      "Content-Type": "text/plain",
    },
  };

  let data = user.username;
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/user/logout`,
    data,
    newConfig
  );
};
// User Apis
