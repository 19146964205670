import {
  AppstoreOutlined,
  ShopOutlined,
  FileExcelOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

import { Button, Menu, Drawer } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SideMenu = ({ userDetails }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState("/");
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const menuItems = [
    {
      label: "Dashboard",
      icon: <AppstoreOutlined />,
      key: "/",
    },
    {
      label: "Till Entry",
      key: "/till-entry",
      icon: <ShopOutlined />,
    },
    {
      label: "Deposit Slip",
      key: "/deposit-slip",
      icon: <ProfileOutlined />,
    },
    {
      label: "Report",
      key: "/report",
      icon: <FileExcelOutlined />,
    },
  ];

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
  }, [location.pathname]);

  const navigate = useNavigate();
  return (
    <>
      <div className="SideMenu">
        <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{
            marginLeft: collapsed ? 16 : 95,

            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          }}
        >
          {collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
        </Button>
        <Menu
          inlineCollapsed={collapsed}
          className="SideMenuVertical"
          mode="inline"
          onClick={(item) => {
            navigate(item.key);
          }}
          selectedKeys={[selectedKeys]}
          items={menuItems.filter((menu) => {
            let privileges = userDetails?.rolePrivilege?.privileges;
            if (
              !privileges?.includes("CAN_VIEW_REPORTS") &&
              menu.label === "Report"
            ) {
              return false;
            } else {
              return true;
            }
          })}
        ></Menu>
      </div>
      {/* <div className="SideMenu sideMenuMobile">
        <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{
            marginLeft: collapsed ? 14 : 95,

            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <Drawer>
          <Menu
            // inlineCollapsed={collapsed}
            className="SideMenuVertical"
            mode="vertical"
            onClick={(item) => {
              navigate(item.key);
            }}
            selectedKeys={[selectedKeys]}
            items={menuItems.filter((menu) => {
              let privileges = userDetails?.rolePrivilege?.privileges;
              if (
                !privileges?.includes("CAN_VIEW_REPORTS") &&
                menu.label === "Report"
              ) {
                return false;
              } else {
                return true;
              }
            })}
          ></Menu>
        </Drawer>
      </div> */}
    </>
  );
};
