import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  message,
  Divider,
  Typography,
  Form,
  Select,
  Radio,
  DatePicker,
  Input,
  Button,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export const ModalFormTable = ({
  title,
  isAdding,
  resetAdding,
  form,
  addRecord,
  formFields,
  formName,
  colData,
  dataIndexes,
  metaData,
  userData,
}) => {
  const [categoryId, setCategoryId] = useState("");
  const [entryId, setEntryId] = useState("");
  const [locationId, setlocationId] = useState("");
  const [entryTypes, setEntryTypes] = useState([]);
  const [tillConfigs, setTillConfigs] = useState([]);
  const [addTillEntries, setAddTillEntries] = useState([]);
  const [entryData, setEntryData] = useState({});
  const [defaultData, setDefaultData] = useState({});

  useEffect(() => {
    if (["deposit_slip", "till_entry"].includes(formName) && categoryId) {
      setEntryId("");
      form.setFieldsValue({ entryTypeId: "", entryPartitionId: "" });
      const entries = formFields.filter((ff) => ff.dataTitle === "entryType")[0]
        .data;
      if (entries.length > 0) {
        setEntryTypes(
          entries.filter((entry) => entry.categoryType.id === categoryId)
        );
      }
    }
  }, [categoryId, formFields, formName, form]);
  useEffect(() => {
    if (["deposit_slip", "till_entry"].includes(formName) && entryId) {
      const entries = formFields.filter((ff) => ff.dataTitle === "entryType")[0]
        .data;
      const selectedEntry = entries.filter((entry) => entry.id === entryId);
      if (entries.length > 0 && selectedEntry.length > 0) {
        setEntryData(selectedEntry[0]);
      }
    }
  }, [entryId, formFields, formName]);

  useEffect(() => {
    if (["deposit_slip", "till_entry"].includes(formName) && locationId) {
      form.setFieldsValue({ tillConfigurationId: "" });
      const tillConfigurations = formFields.filter(
        (ff) => ff.dataTitle === "tillIdentifier"
      )[0].data;
      if (tillConfigurations.length > 0) {
        setTillConfigs(
          tillConfigurations.filter(
            (config) => config.location.id === locationId
          )
        );
      }
    }
  }, [locationId, formFields, formName, form]);

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      form.setFieldsValue({
        tillConfigurationId: defaultData.tillConfigurationId,
        locationId: defaultData.locationId,
        date: dayjs(new Date()),
      });
    }
    if (userData.location) {
      setlocationId(userData.location);
    }
  }, [defaultData, form]);

  const onDeleteRecord = (record) => {
    const updatedTillEntries = addTillEntries.filter(
      (till) => till.id !== record.id
    );
    setAddTillEntries(updatedTillEntries);
  };

  const handleAddData = async (e) => {
    // Add form value getter and then set all values to empty.
    try {
      const values = await form.validateFields();
      const defaultEmpty = Object.keys(defaultData).length > 0;
      if (!defaultEmpty) {
        setDefaultData({
          locationId: values.locationId,
          tillConfigurationId: values.tillConfigurationId,
        });
      }
      const emptyFields = Object.keys(values);
      if (emptyFields.includes("add")) {
        delete values.add;
      }
      if (values?.date) {
        values.date = values.date.format("YYYY-MM-DD");
      }
      if (values?.chequeDate) {
        values.chequeDate = values.chequeDate.format("YYYY-MM-DD");
      }
      const dataIndexArr = Object.keys(dataIndexes);
      emptyFields.forEach((field) => {
        const fieldData = formFields.filter((ff) => ff.dataIndex === field)[0]
          .data;
        if (dataIndexArr.includes(field)) {
          const fieldName = dataIndexes[field];
          const fieldValue = fieldData.filter((fd) => fd.id === values[field]);
          values[fieldName] =
            fieldValue.length > 0 ? fieldValue[0][fieldName] : "";
        }
      });
      setAddTillEntries([...addTillEntries, values]);

      form.resetFields(emptyFields);
      setEntryId("");
      if (defaultEmpty) {
        form.setFieldsValue({
          tillConfigurationId: defaultData.tillConfigurationId,
          locationId: defaultData.locationId,
          date: dayjs(new Date()),
        });
      }
    } catch (error) {
      message.error(error);
    }
  };
  return (
    <Modal
      title={title}
      open={isAdding}
      width={"600px"}
      okText="Save"
      maskClosable={false}
      onCancel={() => {
        setAddTillEntries([]);
        setDefaultData({});
        resetAdding("cancel");
      }}
      onOk={async () => {
        const bulkTills = addTillEntries.map((entry) => {
          const till = { ...entry };
          if (formName === "till_entry") {
            till.metadata = {};
            metaData.forEach((detail) => {
              if (till[detail]) {
                till.metadata[detail] = till[detail];
              }
              delete till[detail];
            });
          }
          if (till.id) {
            delete till.id;
          }
          if (formName === "till_entry" && !till.entryPartitionId) {
            delete till.entryPartitionId;
          }
          if (
            ["deposit_slip", "till_entry"].includes(formName) &&
            till.categoryTypeId
          ) {
            delete till.categoryTypeId;
          }
          const emptyFields = Object.values(dataIndexes);

          emptyFields.forEach((field) => {
            delete till[field];
          });
          return till;
        });

        const promiseArr = bulkTills.map((tills) => {
          return addRecord(tills);
        });

        Promise.all(promiseArr)
          .then((res) => {
            setAddTillEntries([]);
            message.success("Record created successfully.");
            resetAdding("ok");
          })
          .catch((error) => {
            if (error.code !== "ERR_NETWORK") {
              const { data } = error.response;
              message.error(data.message);
            }
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal_add_form">
        {formFields.map((field) => {
          const { title, dataIndex, type, require } = field;
          let hidden = false;
          if (metaData.includes(dataIndex)) {
            if (!entryId || (entryId && !entryData?.hasCheque)) {
              hidden = true;
            }
          }
          if (type === "dropdown") {
            const selectTitle = field.dataTitle;
            let selectData;
            let disable = false;
            if (
              ["name", "tillIdentifier"].includes(selectTitle) &&
              Object.keys(defaultData).length > 0
            ) {
              disable = true;
            }

            if (["deposit_slip", "till_entry"].includes(formName)) {
              if (selectTitle === "entryPartition") {
                if (!entryId || (entryId && !entryData?.hasPartition)) {
                  hidden = true;
                }
              }
              if (selectTitle === "entryType") {
                selectData = entryTypes;
              } else if (selectTitle === "tillIdentifier") {
                selectData = tillConfigs;
              } else {
                selectData = field.data;
              }
            } else {
              selectData = field.data;
            }
            if (selectTitle === "array") {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  <Select>
                    {selectData.map((val, index) => {
                      return (
                        <Select.Option key={index} value={val}>
                          {val}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else if (selectTitle === "multiselect") {
              return (
                <Form.Item
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  <Select mode="multiple" allowClear>
                    {selectData.map((val, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={val.value}
                          label={val.label}
                        >
                          {val.value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  hidden={hidden}
                  label={title}
                  key={dataIndex}
                  name={dataIndex}
                  rules={
                    require
                      ? [
                          {
                            required: true,
                            message: `Please select the ${title}`,
                          },
                        ]
                      : []
                  }
                >
                  {(selectTitle === "categoryType" ||
                    selectTitle === "name" ||
                    selectTitle === "entryType") &&
                  ["deposit_slip", "till_entry"].includes(formName) ? (
                    <Select
                      disabled={disable}
                      value={
                        selectTitle === "categoryType"
                          ? categoryId
                          : selectTitle === "entryType"
                          ? entryId
                          : locationId
                      }
                      onSelect={(e) => {
                        if (selectTitle === "categoryType") {
                          setCategoryId(e);
                        } else if (selectTitle === "entryType") {
                          setEntryId(e);
                        } else {
                          setlocationId(e);
                        }
                      }}
                    >
                      {selectData.map((val, index) => {
                        return (
                          <Select.Option key={index} value={val.id}>
                            {`${val[selectTitle]}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select disabled={disable}>
                      {selectData.map((val, index) => {
                        return (
                          <Select.Option key={index} value={val.id}>
                            {`${val[selectTitle]}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              );
            }
          } else if (type === "radio") {
            return (
              <Form.Item
                label={title}
                key={dataIndex}
                name={dataIndex}
                rules={
                  require
                    ? [
                        {
                          required: { require },
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <Radio.Group>
                  <Radio value="yes"> Yes </Radio>
                  <Radio value="no"> No </Radio>
                </Radio.Group>
              </Form.Item>
            );
          } else if (type === "date") {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                hidden={hidden}
                rules={
                  require
                    ? [
                        {
                          required: { require },
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <DatePicker style={{ width: 200 }} format={"DD/MM/YYYY"} />
              </Form.Item>
            );
          } else if (type === "password") {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            );
          } else if (type === "button") {
            return (
              <Form.Item key={dataIndex} name={dataIndex}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleAddData}
                >
                  Add
                </Button>
              </Form.Item>
            );
          } else {
            return (
              <Form.Item
                key={dataIndex}
                name={dataIndex}
                label={title}
                hidden={hidden}
                rules={
                  require
                    ? [
                        {
                          required: { require },
                          message: `Please input the ${title}`,
                        },
                      ]
                    : []
                }
              >
                <Input />
              </Form.Item>
            );
          }
        })}
      </Form>
      <Divider />

      <Typography.Title level={4}>Till Entries to be Created</Typography.Title>
      <Table
        columns={[
          ...colData,
          {
            key: "action",
            title: "Unassign Action",
            render: (record) => {
              return (
                <>
                  <DeleteOutlined
                    onClick={() => {
                      onDeleteRecord(record);
                    }}
                    style={{ color: "red", marginLeft: 12 }}
                  />
                </>
              );
            },
          },
        ]}
        dataSource={addTillEntries.map((entry, index) => {
          entry.id = index + 1;
          return entry;
        })}
        rowKey="id"
      />
    </Modal>
  );
};
