import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};

// Till Entry Apis
export const getAllTillEntries = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/till-entry`, config);
};

export const getTillEntryById = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-entry/${id}`,
    config
  );
};

export const getAllTillEntriesByLoc = async (loc_id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-entry/location/${loc_id}`,
    config
  );
};

export const getAllTillEntriesByDateRange = async (startDate, endDate) => {
  const user = await getUserData();
  const location = user.location.id;
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-entry/search?location=${location}&startingDate=${startDate}&endingDate=${endDate}`,
    config
  );
};
export const createTillEntry = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/till-entry`,
    values,
    config
  );
};
export const setDepositSlipRequest = async (values) => {
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/till-entry/set-deposit-slip`,
    values,
    config
  );
};
export const deleteTillEntry = async (config_id) => {
  return axios.delete(
    `${process.env.REACT_APP_.BASE_URL}/till-entry/${config_id}`,
    config
  );
};
export const updateTillEntry = async (id, upd_data) => {
  const user = await getUserData();
  upd_data.modifiedBy = user.username;
  upd_data.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/till-entry`,
    upd_data,
    config
  );
};
export const getUnassignedTillEntries = async () => {
  const user = await getUserData();
  const locId = user.location.id;
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-entry/unassigned/location/${locId}`,
    config
  );
};
export const getAssignedTillEntries = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/till-entry/deposit-slip/${id}`,
    config
  );
};
// Till Entry Apis
