import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// Entry Apis

export const getAllEntries = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/entry-type`, config);
};

export const getEntryById = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/entry-type/${id}`,
    config
  );
};
export const createEntry = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  values.hasDepositSlip = values.hasDepositSlip === "yes";
  values.hasCheque = values.hasCheque === "yes";
  values.hasPartition = values.hasPartition === "yes";
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/entry-type`,
    values,
    config
  );
};
export const deleteEntry = async (entry_id) => {
  return axios.delete(
    `${process.env.REACT_APP_.BASE_URL}/entry-type/${entry_id}`,
    config
  );
};
export const updateEntry = async (id, updatedData) => {
  const user = await getUserData();
  updatedData.modifiedBy = user.username;
  updatedData.hasDepositSlip = updatedData.hasDepositSlip === "yes";
  updatedData.hasCheque = updatedData.hasCheque === "yes";
  updatedData.hasPartition = updatedData.hasPartition === "yes";
  updatedData.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/entry-type`,
    updatedData,
    config
  );
};

// Entry Apis
