import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Result } from "antd";

const COLORS = [
  "#7f79f3", //Purple
  "#00C49F", //Green
  "#0088FE", //Blue
  "#FF8042",
  "#FFBB28",
  "#82ca9d",
];
export const BarChartGraph = ({ data, cookies }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let date;
      const data = {};
      Object.keys(payload[0].payload).map((d, index) => {
        if (index) {
          data[d] = Intl.NumberFormat(cookies["Currency"][1], {
            style: "currency",
            currency: cookies["Currency"][0],
          }).format(payload[0].payload[d]);
          return false;
        }
        date = payload[0].payload[d];
        return false;
      });

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "1px 10px 1px 10px" }}
        >
          <h3>
            <b>{date}</b>
          </h3>
          {Object.keys(data).map((d, index) => {
            return (
              <p key={index}>
                {d}: <b>{data[d]}</b>
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };
  return (
    <>
      {data.length ? (
        <BarChart
          width={900}
          height={520}
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 31,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ stroke: "black", strokeWidth: 0.4 }}
          />
          <Legend />
          {Object.keys(data[0])
            .slice(1)
            .map((currentItem, index) => {
              return (
                <Bar key={index} dataKey={currentItem} fill={COLORS[index]} />
              );
            })}
        </BarChart>
      ) : (
        <div className="center">
          <Result title="There is no data to show in the Bar Chart." />
        </div>
      )}
    </>
  );
};
