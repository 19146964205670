import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Col,
  Row,
  Typography,
  Form,
  DatePicker,
  Button,
  message,
  Select,
  Spin,
  Result,
} from "antd";

import { BarChartGraph } from "../Components/BarChartGraph";
import { PieChartGraph } from "../Components/PieChartGraph";
import { ScatteredChartGraph } from "../Components/ScatteredChartGraph";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getAllCategories } from "../services/Category";
import {
  allCategoriesBarChart,
  allCategoriesPieChart,
  categoryBarChart,
} from "../services/Dashboard";

export const Dashboard = ({ cookies }) => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [allCategory, setAllCategory] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [barChartFlag, setBarChartFlag] = useState(false);
  const [scatterChartData, setScatterChartData] = useState({});
  const [startingDate, setStartingDate] = useState();
  const [endingDate, setEndingDate] = useState();
  const [dateFilter, setDateFilter] = useState(false);
  const [chartFilter, setChartFilter] = useState(false);
  const [pieChartLoader, setPieChartLoader] = useState(true);
  const [chartLoader, setChartLoader] = useState(true);
  const [sourceData, setSourceData] = useState("");
  const [category, setCategory] = useState("");

  const months = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const fetchCategories = async () => {
    try {
      const res = await getAllCategories();
      if (res?.status === 200) {
        setAllCategory(res.data);
      }
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        message.error(error.message);
      }
    }
  };

  const fetchPieChartData = async (startDate, endDate) => {
    try {
      const res = await allCategoriesPieChart(startDate, endDate);
      if (res?.status === 200) {
        let modelData = res.data?.map((d) => {
          return { name: d.categoryType, value: d.amount };
        });
        setPieChartData(modelData);
      }
      setPieChartLoader(false);
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        message.error(error.message);
      }
    }
  };

  const ChartData = async (startDate, endDate, categories) => {
    try {
      let res;
      if (categories) {
        res = await categoryBarChart(categories, startDate, endDate);
      } else {
        res = await allCategoriesBarChart(startDate, endDate);
      }
      if (res?.status === 200) {
        if (res.data.length) {
          res.data.sort((a, b) => {
            if (a.date[1] > b.date[1] && a.date[0] >= b.date[0]) {
              return 1;
            }
            if (
              a.date[1] === b.date[1] &&
              a.date[0] === b.date[0] &&
              a.date[2] > b.date[2]
            ) {
              return 1;
            }
            return -1;
          });
        }
        setSourceData(res.data);
        return res.data;
      }
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        message.error(error.message);
      }
    }
  };

  const fetchScatterChartData = async (data, categories) => {
    const responseData = data;

    if (!responseData?.length) {
      setScatterChartData({});
      setChartLoader(false);
      return;
    }

    let accData = responseData.reduce((acc, dt) => {
      let dateFormat = `${dt.date[0]}-${
        dt.date[1] < 10 ? `0${dt.date[1]}` : dt.date[1]
      }-${dt.date[2] < 10 ? `0${dt.date[2]}` : dt.date[2]}`;

      let date = new Date(dateFormat).getTime();
      if (!categories) {
        dt.data.forEach((d) => {
          if (acc[d.categoryType]) {
            acc[d.categoryType].push({ x: date, y: d.amount });
          } else {
            acc[d.categoryType] = [{ x: date, y: d.amount }];
          }
        });
      } else {
        if (acc[dt.data.categoryType]) {
          acc[dt.data.categoryType].push({ x: date, y: dt.data.amount });
        } else {
          acc[dt.data.categoryType] = [{ x: date, y: dt.data.amount }];
        }
      }
      return acc;
    }, {});
    setScatterChartData(accData);
    setChartLoader(false);

    return;
  };

  const fetchBarChartData = useCallback(
    async (data, categories) => {
      const responseData = data;
      if (!responseData?.length) {
        setBarChartData(data);
        setChartLoader(false);
        return;
      }
      let accData = responseData.reduce((acc, dt) => {
        let month = months[dt.date[1] - 1];
        let year = dt.date[0];

        let index = acc.findIndex((a) => a.name === `${month} ${year}`);
        if (!categories) {
          if (index === -1) {
            let obj = {
              name: `${month} ${year}`,
            };
            allCategory.map((category) => {
              return (obj[category.categoryType] = 0);
            });
            dt.data.forEach((d) => {
              const cat = d.categoryType;
              obj[cat] = d.amount;
            });

            acc.push(obj);
          } else {
            dt.data.forEach((d) => {
              const cat = d.categoryType;
              if (acc[index][cat]) {
                acc[index][cat] = acc[index][cat] + d.amount;
              } else {
                acc[index][cat] = d.amount;
              }
            });
          }
        } else {
          if (index === -1) {
            let obj = {
              name: `${month} ${year}`,
            };
            obj[dt.data.categoryType] = dt.data.amount;
            acc.push(obj);
          } else {
            acc[index][dt.data.categoryType] =
              acc[index][dt.data.categoryType] + dt.data.amount;
          }
        }
        return acc;
      }, []);

      setBarChartData(accData);
      setChartLoader(false);
    },
    [months, allCategory]
  );
  const handleChartTypeChange = (value) => {
    setChartLoader(true);
    if (value && startingDate && endingDate) {
      setChartFilter(true);
      if (value === "scattered-chart") {
        setBarChartFlag(false);
        setBarChartData(false);
        fetchScatterChartData(sourceData, category);
      } else if (value === "bar-chart") {
        setBarChartFlag(true);
        fetchBarChartData(sourceData, category);
      }
    }
    if (!value) {
      setChartFilter(false);
    }
  };
  const handleCategoryChange = async (value) => {
    setChartLoader(true);

    const data = await ChartData(startingDate, endingDate, value);
    if (value) {
      setCategory(value);
      if (form.getFieldValue("chart_type") === "bar-chart") {
        await fetchBarChartData(data, value);
        setBarChartFlag(true);
      } else if (form.getFieldValue("chart_type") === "scattered-chart") {
        await fetchScatterChartData(data, value);
        setBarChartFlag(false);
      }
    } else {
      setCategory("");

      if (form.getFieldValue("chart_type") === "bar-chart") {
        await fetchBarChartData(data, "");
        setBarChartFlag(true);
      } else if (form.getFieldValue("chart_type") === "scattered-chart") {
        await fetchScatterChartData(data, "");
        setBarChartFlag(false);
      }
    }
  };
  const handleDateChange = (value) => {
    if (!value) {
      setDateFilter(false);
    }
  };
  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    form.setFieldsValue({
      date_range: [dayjs(currentDate), dayjs(currentDate)],
      chart_type: "bar-chart",
    });
    setDateFilter(true);
    setStartingDate(currentDate);
    setEndingDate(currentDate);

    setChartFilter(true);
    setChartLoader(true);
    setPieChartLoader(true);
    setBarChartFlag(true);

    fetchCategories();

    fetchPieChartData(currentDate, currentDate);
    const barChart = async () => {
      const data = await ChartData(currentDate, currentDate, "");
      fetchBarChartData(data, "");
    };
    barChart();
  }, [form]);

  const onFinish = async (values) => {
    if (values.date_range) {
      setPieChartLoader(true);
      setDateFilter(true);
      setCategory(values.category ? values.category : "");
      try {
        fetchPieChartData(
          values?.date_range[0].format("YYYY-MM-DD"),
          values?.date_range[1].format("YYYY-MM-DD")
        );
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      if (values.date_range && values.chart_type) {
        setChartLoader(true);
        setStartingDate(values?.date_range[0].format("YYYY-MM-DD"));
        setEndingDate(values?.date_range[1].format("YYYY-MM-DD"));
        let data = await ChartData(
          values?.date_range[0].format("YYYY-MM-DD"),
          values?.date_range[1].format("YYYY-MM-DD"),
          values.category ? values.category : ""
        );
        setChartFilter(true);
        if (values.chart_type === "bar-chart") {
          setBarChartFlag(true);
          await fetchBarChartData(data, category);
        } else {
          setBarChartFlag(false);
          await fetchScatterChartData(data, category);
        }
      } else {
        setChartFilter(false);
        setBarChartData([]);
        setScatterChartData({});
      }
    } else {
      setDateFilter(false);
      setPieChartData([]);
      setBarChartData([]);
      setScatterChartData({});
    }
  };

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Admin Dashboard
      </Typography.Title>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        style={{ justifyContent: "space-evenly" }}
      >
        <Row style={{ marginTop: "30px", justifyContent: "center" }}>
          <Col>
            <Form.Item
              name="date_range"
              label="Range Picker "
              colon={false}
              labelWrap
            >
              <RangePicker onChange={handleDateChange} />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <SearchOutlined></SearchOutlined>Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px", marginLeft: "250px" }}>
          <Col>
            <Form.Item name="category">
              <Select
                onChange={handleCategoryChange}
                allowClear={true}
                style={{ width: 180 }}
                placeholder="Select a Category"
              >
                {allCategory.map((val, index) => {
                  return (
                    <Select.Option key={index} value={val["categoryType"]}>
                      {val["categoryType"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="chart_type">
              <Select
                onChange={handleChartTypeChange}
                allowClear={true}
                style={{ width: 180 }}
                placeholder="Select the chart-type"
              >
                <Select.Option
                  key={"scattered-chart"}
                  value={"scattered-chart"}
                >
                  Scattered Chart
                </Select.Option>
                <Select.Option key={"bar-chart"} value={"bar-chart"}>
                  Bar Chart
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {dateFilter ? (
        <Row>
          {chartFilter ? (
            chartLoader ? (
              <Col flex="auto">
                <div className="loader" width={400}>
                  <Spin tip="Loading Chart" size="large">
                    <div className="content" />
                  </Spin>
                </div>
              </Col>
            ) : (
              <Col flex="auto">
                {barChartFlag ? (
                  <BarChartGraph
                    data={barChartData}
                    cookies={cookies}
                  ></BarChartGraph>
                ) : (
                  <ScatteredChartGraph
                    data={scatterChartData}
                    startingDate={new Date(startingDate)}
                    endingDate={new Date(endingDate)}
                    cookies={cookies}
                  ></ScatteredChartGraph>
                )}
              </Col>
            )
          ) : (
            <Col flex="auto">
              <div className="center">
                <Result status="warning" title=" Please select chart type. " />
              </div>
            </Col>
          )}
          {pieChartLoader ? (
            <Col flex="360px">
              <div className="loader">
                <Spin tip="Loading Pie Chart" size="large">
                  <div className="content" />
                </Spin>
              </div>
            </Col>
          ) : (
            <Col flex="360px">
              <PieChartGraph
                data={pieChartData}
                cookies={cookies}
              ></PieChartGraph>
            </Col>
          )}
        </Row>
      ) : (
        <div className="center">
          <Result
            status="warning"
            title="Please select Start-Date and End-Date for charts."
          />
        </div>
      )}
    </>
  );
};
