import "../App.css";
import React, { useEffect, useState } from "react";
import { Result } from "antd";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { add, format, differenceInCalendarDays } from "date-fns";
const COLORS = [
  "#0088FE", //Blue
  "#00C49F", //Green
  "#7f79f3", //Purple
  "#FF8042",
  "#FFBB28",
  "#82ca9d",
];
export const ScatteredChartGraph = ({
  data,
  startingDate,
  endingDate,
  cookies,
}) => {
  const [opacity, setOpacity] = useState(false);
  const [domain, setDomain] = useState([]);
  const [ticks, setTicks] = useState([]);

  const getTicks = (startDate, endDate, num) => {
    let current = startDate;
    if (startDate.getTime() === endDate.getTime()) {
      startDate = add(current, { days: -1 });
      endDate = add(current, { days: 1 });
      current = startDate;
    }
    let newDomain = [() => startDate.getTime(), () => endDate.getTime()];

    if (!domain.length || !newDomain.every((d, i) => d() === domain[i]())) {
      setDomain(newDomain);
    }
    let diffDays = differenceInCalendarDays(endDate, startDate);

    if (diffDays < 10) {
      num = diffDays + 1;
    }
    let velocity = Math.round(diffDays / (num - 1));

    const ticks = [startDate.getTime()];
    for (let i = 1; i < num - 1; i++) {
      ticks.push(add(current, { days: i * velocity }).getTime());
    }

    ticks.push(endDate.getTime());
    let filteredTicks = [];
    ticks.filter((tick) => {
      if (!filteredTicks.includes(tick)) {
        filteredTicks.push(tick);
      }
      return true;
    });
    return filteredTicks;
  };
  useEffect(() => {
    if (Object.keys(data).length) {
      let newticks = getTicks(startingDate, endingDate, 10);

      if (!newticks.every((tk) => ticks.includes(tk))) {
        setTicks(newticks);
      }

      let obj = {};
      Object.keys(data).map((d) => {
        obj[d] = 1;
        return false;
      });

      if (!opacity) {
        setOpacity(obj);
      }
    }
  }, [opacity, ticks, domain, startingDate, data, endingDate]);
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const date = dateFormatter(payload[0].value);
      const value = Intl.NumberFormat(cookies["Currency"][1], {
        style: "currency",
        currency: cookies["Currency"][0],
      }).format(payload[1].value);

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "1px 10px 1px 10px" }}
        >
          <p>Date: {date}</p>
          <p>Amount: {value}</p>
        </div>
      );
    }

    return null;
  };
  const dateFormatter = (date) => {
    return format(new Date(date), "dd MMM, yy");
  };

  const handleMouseEnter = (e) => {
    const { value } = e;

    setOpacity(
      Object.keys(opacity).reduce((acc, op) => {
        if (value !== op) {
          acc[op] = 0.5;
        } else {
          acc[op] = 1;
        }
        return acc;
      }, {})
    );
  };

  const handleMouseLeave = (o) => {
    setOpacity(
      Object.keys(opacity).reduce((acc, op) => {
        acc[op] = 1;
        return acc;
      }, {})
    );
  };

  return (
    <>
      {Object.keys(data).length ? (
        <ScatterChart
          width={900}
          height={520}
          margin={{
            top: 20,
            right: 37,
            bottom: 20,
            left: 31,
          }}
        >
          <CartesianGrid />

          <XAxis
            dataKey="x"
            hasTick
            scale="time"
            tickFormatter={dateFormatter}
            type="number"
            domain={domain}
            ticks={ticks}
            name="Date"
          />

          <YAxis type="number" dataKey="y" name="Amount" />

          <Tooltip
            content={<CustomTooltip />}
            cursor={{ stroke: "black", strokeWidth: 0.4 }}
          />

          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {Object.keys(data).map((currentItem, index) => {
            return (
              <Scatter
                key={index}
                name={currentItem}
                data={data[currentItem]}
                fill={COLORS[index]}
                line
                shape="circle"
                strokeOpacity={opacity ? opacity[currentItem] : 1}
              />
            );
          })}
        </ScatterChart>
      ) : (
        <div className="center">
          <Result title="There is no data to show in the Scatter joint line chart." />
        </div>
      )}
    </>
  );
};
