import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
// const getUserData = async () => {
//   try {
//     const user = await axios.get(
//       `${process.env.REACT_APP_.BASE_URL}/user/myself`,
//       config
//     );
//     return user.data[0];
//   } catch (error) {
//     console.log("User Data can not be extracted", error);
//   }
// };

// Role Apis
export const getAllRolePrivileges = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/role`, config);
};
export const getRolePrivilegeByRole = async (role) => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/role/${role}`, config);
};
export const createRolePrivilege = async (values) => {
  // const user = await getUserData();
  // Doesn't require modifiedBy
  // values.modifiedBy = user.username;
  return axios.post(`${process.env.REACT_APP_.BASE_URL}/role`, values, config);
};
export const deleteRolePrivilege = async (del_data) => {
  config.data = del_data;
  return axios.delete(`${process.env.REACT_APP_.BASE_URL}/role`, config);
};
export const updateRolePrivilege = async (upd_data) => {
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/role`,
    upd_data,
    config
  );
};
export const getRolePrivileges = async () => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/role/privilege/types`,
    config
  );
};
export const getRoleTypes = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/role/types`, config);
};
// Role Apis
