import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Form,
  message,
  Space,
  Select,
  DatePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Toast } from "antd-mobile";
import {
  createTillEntry,
  deleteTillEntry,
  getAllTillEntries,
  getAllTillEntriesByLoc,
  updateTillEntry,
  getAllTillEntriesByDateRange,
  getTillEntryById,
} from "../services/TillEntry";
import { getAllTillConfigurations } from "../services/TillConfiguration";
import { getAllEntriesPartition } from "../services/EntryPartition";
import { getAllLocations } from "../services/TillLocation";
import { getAllEntries } from "../services/Entry";
import { getUserData } from "../services/User";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";
import { ModalFormTable } from "../Components/ModalFormTable";
import dayjs from "dayjs";
import { format } from "date-fns";
export const TillEntry = ({ userInfo, cookies }) => {
  const [colData, setColData] = useState([]);
  const [addColData, setAddColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const search = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [allTillConfig, setAllTillConfig] = useState([]);
  const [allEntries, setAllEntries] = useState([]);
  const [allEntryPartitions, setAllEntryPartitions] = useState([]);
  const [allTillLocation, setAllTillLocation] = useState([]);
  const [dataIndexes, setDataIndexes] = useState({});
  // const [location, setLocation] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const metaData = ["chequeNo", "bankName", "chequeDate", "phoneNo"];
  const [cookie, setCookie] = useState({});
  const [user, setUser] = useState("");

  const { RangePicker } = DatePicker;
  useEffect(() => {
    const getUser = async () => {
      const data = await getUserData();
      if (data) {
        setUser({
          username: data.username,
          role: data.rolePrivilege.role,
        });
      }
    };
    getUser();
  }, []);
  const dateFormatter = (date) => {
    return format(new Date(date), "dd MMM, yyyy");
  };
  const onFinish = async (values) => {
    setLoading(true);
    const { date_range, location } = values;

    try {
      let res;
      if (date_range && date_range.length > 0) {
        res = await getAllTillEntriesByDateRange(
          date_range[0].format("YYYY-MM-DD"),
          date_range[1].format("YYYY-MM-DD")
        );
      } else if (location) {
        res = await getAllTillEntriesByLoc(location);
      } else {
        res = await getAllTillEntries();
      }
      if (res?.status === 200) {
        setDataSource(res.data);
      }

      try {
        const res = await getAllTillConfigurations();
        if (res?.status === 200) {
          setAllTillConfig(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        message.error(error.message);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    setCookie(cookies);
  }, [cookies]);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    search[0].setFieldsValue({
      date_range: [dayjs(currentDate), dayjs(currentDate)],
    });
  }, [search]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        const res = await getAllTillEntries();
        if (res?.status === 200) {
          setDataSource(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllTillConfigurations();
        if (res?.status === 200) {
          setAllTillConfig(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllEntries();
        if (res?.status === 200) {
          setAllEntries(res.data);
          const catArr = [];
          res.data.forEach((entry) => {
            const index = catArr.findIndex((ca) => {
              return ca.id === entry.categoryType.id;
            });
            if (index === -1) {
              catArr.push(entry.categoryType);
            }
          });
          setCategoryList(catArr);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllEntriesPartition();
        if (res?.status) {
          setAllEntryPartitions(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllLocations();
        if (res?.status === 200) {
          setAllTillLocation(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      setAddColData([
        {
          title: "Category Type",
          dataIndex: "categoryType",
        },
        {
          title: "Entry Type",
          dataIndex: "entryType",
        },
        {
          title: "Entry Partition",
          dataIndex: "entryPartition",
        },
        {
          title: "Amount",
          dataIndex: "entryAmount",
        },
      ]);
      setDataIndexes({
        categoryTypeId: "categoryType",
        entryTypeId: "entryType",
        entryPartitionId: "entryPartition",
      });

      setColData([
        {
          title: "Till Configuration",
          dataIndex: "tillConfiguration",
          render: (record) => {
            return record?.tillIdentifier;
          },
        },
        {
          title: "Entry Type",
          dataIndex: "entryType",
          render: (record) => {
            return record?.entryType;
          },
        },
        {
          title: "Entry Partition",
          dataIndex: "entryPartition",
          render: (record) => {
            return record?.entryPartition;
          },
        },

        {
          title: "Date",
          dataIndex: "date",
          render: (record) => {
            return dateFormatter(record);
          },
        },
        {
          title: "Amount",
          dataIndex: "entryAmount",
        },
        {
          title: "Modified By",
          dataIndex: "modifiedBy",
        },
      ]);
      setReload(false);
      setLoading(false);
    };

    getData();
  }, [reload]);
  useEffect(() => {
    setFormFields([
      {
        title: "Location",
        dataIndex: "locationId",
        type: "dropdown",
        require: true,
        editDisable: true,
        data: allTillLocation,
        dataTitle: "name",
      },
      {
        title: "Till Configuration",
        dataIndex: "tillConfigurationId",
        type: "dropdown",
        require: true,
        data: allTillConfig,
        dataTitle: "tillIdentifier",
      },
      {
        title: "Date",
        dataIndex: "date",
        type: "date",
        require: true,
      },
      {
        title: "Category Type",
        dataIndex: "categoryTypeId",
        type: "dropdown",
        require: true,
        data: categoryList,
        dataTitle: "categoryType",
      },
      {
        title: "Entry Type",
        dataIndex: "entryTypeId",
        type: "dropdown",
        require: true,
        data: allEntries,
        dataTitle: "entryType",
      },
      {
        title: "Entry Partition",
        dataIndex: "entryPartitionId",
        type: "dropdown",
        require: false,
        data: allEntryPartitions,
        dataTitle: "entryPartition",
      },
      {
        title: "Cheque #",
        dataIndex: "chequeNo",
        type: "text",
        require: false,
      },
      {
        title: "Bank Name",
        dataIndex: "bankName",
        type: "text",
        require: false,
      },
      {
        title: "Cheque Date",
        dataIndex: "chequeDate",
        type: "date",
        require: false,
      },
      {
        title: "Phone #",
        dataIndex: "phoneNo",
        type: "text",
        require: false,
      },
      {
        title: "Amount",
        dataIndex: "entryAmount",
        type: "text",
        require: true,
      },
    ]);
  }, [
    allTillConfig,
    allEntryPartitions,
    allEntries,
    categoryList,
    allTillLocation,
  ]);

  const onAddRecord = () => {
    setIsAdding(true);
    const fieldsValue = formFields.reduce((fieldValues, field) => {
      if (field.dataIndex === "locationId" && userInfo.location) {
        fieldValues[field.dataIndex] = userInfo.location;
      } else if (field.dataIndex === "date") {
        fieldValues[field.dataIndex] = dayjs(new Date());
      } else {
        fieldValues[field.dataIndex] = "";
      }
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setSelectedRecord(null);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };

  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4}>Till Entry Details</Typography.Title>
        <Space>
          <Form
            form={search[0]}
            onFinish={onFinish}
            autoComplete="off"
            layout={"inline"}
            style={{ justifyContent: "space-evenly" }}
          >
            <Form.Item name="date_range">
              <RangePicker />
            </Form.Item>
            {["ROLE_ADMIN", "ROLE_DEVELOPER"].includes(user?.role) && (
              <Form.Item name="location">
                <Select
                  allowClear={true}
                  style={{ width: 180 }}
                  placeholder="Select a Location"
                >
                  {allTillLocation.map((val, index) => {
                    return (
                      <Select.Option key={index} value={val.id}>
                        {val["name"]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <SearchOutlined></SearchOutlined>Search
              </Button>
            </Form.Item>
          </Form>

          <Button
            type="primary"
            onClick={() => {
              if (allTillConfig.length === 0) {
                Toast.show({
                  content: "Create Till Config First",
                  duration: 2000,
                });
              } else {
                onAddRecord();
              }
            }}
          >
            Add
          </Button>
        </Space>
      </div>
      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        deleteRecord={deleteTillEntry}
        form={form}
        formName={"till_entry"}
        setIsEditing={setIsEditing}
        editingRecord={setSelectedRecord}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getTillEntryById}
        metaData={metaData}
        cookies={cookie}
      />
      {isEditing && (
        <EditRecord
          title="Edit Till Entry"
          isEditing={isEditing}
          resetEditing={resetEditing}
          form={form}
          updateRecord={updateTillEntry}
          editData={selectedRecord}
          formFields={formFields}
          formName={"till_entry"}
          metaData={metaData}
        />
      )}
      {isAdding && (
        <ModalFormTable
          title="Add Till Entry"
          isAdding={isAdding}
          resetAdding={resetAdding}
          form={form}
          addRecord={createTillEntry}
          formFields={[
            ...formFields,
            {
              title: "Add",
              type: "button",
              dataIndex: "add",
            },
          ]}
          formName={"till_entry"}
          colData={addColData}
          dataIndexes={dataIndexes}
          metaData={metaData}
          userData={userInfo}
        />
      )}
    </div>
  );
};
