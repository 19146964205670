import React, { useState, useEffect } from "react";
import { Button, Typography, Form, message, DatePicker, Table } from "antd";
import dayjs from "dayjs";
import { format } from "date-fns";
import {
  getAllReportsByDateRange,
  getAllReportsForExportSearch,
} from "../services/Report";
import { DownloadOutlined } from "@ant-design/icons";
export const Report = ({ cookies }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    form.setFieldsValue({
      date_range: [dayjs(currentDate), dayjs(currentDate)],
    });
  }, [form]);
  const dateFormatter = (date) => {
    return format(new Date(date), "dd MMM, yyyy");
  };
  const onFinish = async (values) => {
    setLoading(true);

    setFile(null);
    if (values.date_range) {
      setDisable(false);
      try {
        const response = await getAllReportsForExportSearch(
          values?.date_range[0].format("YYYY-MM-DD"),
          values?.date_range[1].format("YYYY-MM-DD")
        );
        const blob = response.data;
        setFile(blob);
      } catch (error) {
        message.error("Error downloading Excel file:", error);
      }
      try {
        const res = await getAllReportsByDateRange(
          values?.date_range[0].format("YYYY-MM-DD"),
          values?.date_range[1].format("YYYY-MM-DD")
        );
        if (res?.status === 200) {
          setDataSource(
            res.data.map((rdata, index) => {
              rdata.id = index + 1;
              return rdata;
            })
          );
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
    } else {
      setDataSource([]);
    }
    setLoading(false);
  };
  const handleChange = (value) => {
    if (!value) {
      setDisable(true);
    }
  };
  const downloadFile = () => {
    if (file) {
      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(
        new Blob([file], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      link.download = `Report-${new Date().toISOString().split("T")[0]}`;
      link.click();
      // Clean up after the download
      URL.revokeObjectURL(link.href);
    }
  };

  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4} style={{ display: "contents" }}>
          Report Generation
        </Typography.Title>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout={"inline"}
        >
          <Form.Item name="date_range" label="Range Picker">
            <RangePicker onChange={handleChange} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
        <div style={{ marginTop: "20px", display: "contents" }}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadFile}
            disabled={disable}
          >
            Download File
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        columns={[
          {
            title: "Category Type",
            dataIndex: "data",
            render: (record) => {
              return record[0]?.categoryType;
            },
          },
          {
            title: "Entry Type",
            dataIndex: "data",
            render: (record) => {
              return record[0]?.entryType;
            },
          },
          {
            title: "Amount",
            dataIndex: "data",
            render: (record) => {
              const amountFormated = new Intl.NumberFormat(
                cookies["Currency"][1],
                {
                  style: "currency",
                  currency: cookies["Currency"][0],
                }
              ).format(record[0]?.amount);
              return amountFormated;
            },
          },
          {
            title: "Expense",
            dataIndex: "data",
            render: (record) => {
              return record[0]?.isExpense ? "Yes" : "No";
            },
          },
          {
            title: "Date",
            dataIndex: "date",
            render: (record) => {
              const formatDate = dateFormatter(record);
              return formatDate;
            },
          },
        ]}
        dataSource={dataSource}
        pagination={{
          pageSize: 8,
        }}
        rowKey={"id"}
      ></Table>
    </div>
  );
};
