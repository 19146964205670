import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// Category Apis

export const getAllCategories = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/category-type`, config);
};

export const getCategoryById = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/category-type/${id}`,
    config
  );
};

export const createCategory = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  if (values.isExpense === "yes") {
    values.isExpense = true;
  } else {
    values.isExpense = false;
  }
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/category-type`,
    values,
    config
  );
};
export const deleteCategory = async (category_id) => {
  return axios.delete(
    `${process.env.REACT_APP_.BASE_URL}/category-type/${category_id}`,
    config
  );
};
export const updateCategory = async (id, updatedData) => {
  const user = await getUserData();
  updatedData.modifiedBy = user.username;
  if (updatedData.isExpense === "yes") {
    updatedData.isExpense = true;
  } else {
    updatedData.isExpense = false;
  }
  updatedData.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/category-type`,
    updatedData,
    config
  );
};

// Category Apis
