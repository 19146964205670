import { useEffect } from "react";
import { Header } from "../Components/Header";
import { Footer } from "../Components/Footer";
import { SideMenu } from "../Components/SideMenu";
import { PageContent } from "../Components/PageContent";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export const Home = ({ PageComponent, reload, userData }) => {
  const [cookies, setCookie, removeSession] = useCookies(["Currency"]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies.Currency) {
      setCookie("Currency", ["MWK", "mwk"]);
    }
  }, [userData, navigate, cookies, setCookie]);
  return (
    <div className="App">
      <Header userDetails={userData} cookies={cookies} setCookie={setCookie} />
      <div className="SideMenuAndPageContent">
        <div className="SideMenu">
          <SideMenu userDetails={userData}></SideMenu>
        </div>

        <PageContent
          PageComponent={PageComponent}
          userDetails={userData}
          cookies={cookies}
          reloading={reload}
        ></PageContent>
      </div>
      <Footer />
    </div>
  );
};
