import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// Entry partition Apis

export const getAllEntriesPartition = async () => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/entry-partition`,
    config
  );
};

export const getEntryPartitionById = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/entry-partition/${id}`,
    config
  );
};
export const createEntryPartition = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  if (values.hasDepositSlip === "yes") {
    values.hasDepositSlip = true;
  } else {
    values.hasDepositSlip = false;
  }
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/entry-partition`,
    values,
    config
  );
};
export const deleteEntryPartition = async (entry_id) => {
  return axios.delete(
    `${process.env.REACT_APP_.BASE_URL}/entry-partition/${entry_id}`,
    config
  );
};
export const updateEntryPartition = async (id, updatedData) => {
  const user = await getUserData();
  updatedData.modifiedBy = user.username;
  if (updatedData.hasDepositSlip === "yes") {
    updatedData.hasDepositSlip = true;
  } else {
    updatedData.hasDepositSlip = false;
  }
  updatedData.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/entry-partition`,
    updatedData,
    config
  );
};

// Entry Partition Apis
