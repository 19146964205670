import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// Dashboard Apis

export const allCategoriesBarChart = async (startDate, endDate) => {
  const user = await getUserData();

  return axios.get(
    `${
      process.env.REACT_APP_.BASE_URL
    }/dashboard/all-categories?startingDate=${startDate}&endingDate=${endDate}&
    ${user?.location ? "&location=" + user.location.id : ""}`,
    config
  );
};

export const allCategoriesPieChart = async (startDate, endDate) => {
  const user = await getUserData();

  return axios.get(
    `${
      process.env.REACT_APP_.BASE_URL
    }/dashboard/all-categories/pie?startingDate=${startDate}&endingDate=${endDate}&
    ${user?.location ? "&location=" + user.location.id : ""}`,
    config
  );
};

export const categoryBarChart = async (categoryName, startDate, endDate) => {
  const user = await getUserData();

  return axios.get(
    `${
      process.env.REACT_APP_.BASE_URL
    }/dashboard/category/${categoryName}?startingDate=${startDate}&endingDate=${endDate}&
    ${user?.location ? "&location=" + user.location.id : ""}`,
    config
  );
};

// Dasboard Apis
