import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const getUserData = async () => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_.BASE_URL}/user/myself`,
      config
    );
    return user.data;
  } catch (error) {
    throw error;
  }
};
// Deposit Slip Apis
export const getAllDepositSlip = async () => {
  return axios.get(`${process.env.REACT_APP_.BASE_URL}/deposit-slip`, config);
};
export const getDepositSlipById = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/deposit-slip/${id}`,
    config
  );
};
export const getAllDepositSlipByLoc = async (loc_id) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/deposit-slip/location/${loc_id}`,
    config
  );
};
export const getAllDepositSlipByDateRange = async (startDate, endDate) => {
  return axios.get(
    `${process.env.REACT_APP_.BASE_URL}/deposit-slip/${startDate}/${endDate}`,
    config
  );
};
export const createDepositSlip = async (values) => {
  const user = await getUserData();
  values.modifiedBy = user.username;
  values.locationId = user.location.id;
  return axios.post(
    `${process.env.REACT_APP_.BASE_URL}/deposit-slip`,
    values,
    config
  );
};
export const deleteDepostiSlip = async (config_id) => {
  return axios.delete(
    `${process.env.REACT_APP_.BASE_URL}/deposit-slip/${config_id}`,
    config
  );
};
export const updateDepositSlip = async (id, upd_data) => {
  const user = await getUserData();
  upd_data.modifiedBy = user.username;
  upd_data.id = id;
  return axios.put(
    `${process.env.REACT_APP_.BASE_URL}/deposit-slip`,
    upd_data,
    config
  );
};
// Deposit Slip Apis
