import React from "react";
import { Typography } from "antd";

export const Footer = () => {
  return (
    <div className="AppFooter">
      <Typography.Text>
        Web Application ©2023 Powered by Tech Solutions
      </Typography.Text>
    </div>
  );
};
