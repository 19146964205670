import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/User";
import { useCookies } from "react-cookie";

export const Login = ({ reload }) => {
  const [loading, setLoading] = useState(false);
  const [session] = useCookies("SESSION");
  const navigate = useNavigate();
  //from submit
  const submitHandler = async (values) => {
    try {
      setLoading(true);

      await loginUser(values);

      setLoading(false);

      message.success("login success");

      navigate("/");
      reload(true);
    } catch (error) {
      setLoading(false);
      message.error("username and password are not correct.");
    }
  };

  // prevent for login user
  useEffect(() => {
    if (session.SESSION) {
      navigate("/");
    }
  }, [navigate, session]);
  const onFinishFailed = (errorInfo) => {
    message.error("Failed:", errorInfo);
  };
  return (
    <div className="login-page">
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: "22px 40px 12px 35px",
          textAlign: "center",
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitHandler}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <h1>Login</h1>
        <br />
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
