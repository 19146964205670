import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Form,
  message,
  Space,
  Select,
  DatePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Toast } from "antd-mobile";
import dayjs from "dayjs";
import {
  createDepositSlip,
  deleteDepostiSlip,
  getAllDepositSlip,
  getAllDepositSlipByLoc,
  updateDepositSlip,
  getAllDepositSlipByDateRange,
  getDepositSlipById,
} from "../services/DepositSlip";
import { getAllEntries } from "../services/Entry";
import {
  setDepositSlipRequest,
  getAssignedTillEntries,
  getUnassignedTillEntries,
  updateTillEntry,
} from "../services/TillEntry";
import { getAllLocations } from "../services/TillLocation";
import { getUserData } from "../services/User";
import { AddRecord } from "../Components/AddRecord";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";
import { ModalTable } from "../Components/ModalTable";
export const DepositSlip = ({ userInfo, cookies }) => {
  const [allEntries, setAllEntries] = useState([]);
  const [colData, setColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const search = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedDepositId, setSelectedDepositId] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tillAdding, setTillAdding] = useState(false);
  const [tillColData, setTillColData] = useState([]);
  const [allTillLocation, setAllTillLocation] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [user, setUser] = useState("");

  const { RangePicker } = DatePicker;

  useEffect(() => {
    const getUser = async () => {
      const data = await getUserData();
      if (data) {
        setUser({
          username: data.username,
          role: data.rolePrivilege.role,
        });
      }
    };
    getUser();
  }, []);
  const onFinish = async (values) => {
    setLoading(true);
    const { date_range, location } = values;

    try {
      let res;
      if (date_range && date_range.length > 0) {
        res = await getAllDepositSlipByDateRange(
          date_range[0].format("YYYY-MM-DD"),
          date_range[1].format("YYYY-MM-DD")
        );
      } else if (location) {
        res = await getAllDepositSlipByLoc(location);
      }
      if (res?.status === 200) {
        setDataSource(res.data);
      }
      if (date_range || location) {
        try {
          let res = await getAllEntries();
          if (res?.status === 200) {
            setAllEntries(res.data);
          }
        } catch (error) {
          if (error.code !== "ERR_NETWORK") {
            message.error(error.message);
          }
        }
      }
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        message.error(error.message);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    search[0].setFieldsValue({
      date_range: [dayjs(currentDate), dayjs(currentDate)],
    });
  }, [search]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        const res = await getAllDepositSlip();
        if (res?.status === 200) {
          setDataSource(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllEntries();
        if (res?.status === 200) {
          setAllEntries(res.data);
          const catArr = [];
          res.data.forEach((entry) => {
            const index = catArr.findIndex((ca) => {
              return ca.id === entry.categoryType.id;
            });
            if (index === -1) {
              catArr.push(entry.categoryType);
            }
          });
          setCategoryList(catArr);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllLocations();
        if (res?.status === 200) {
          setAllTillLocation(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }

      setReload(false);
      setLoading(false);
    };
    getData();
  }, [reload]);

  useEffect(() => {
    setColData([
      {
        title: "Entry Type",
        dataIndex: "entryType",
        render: (record) => {
          return record?.entryType;
        },
      },
      {
        title: "Till Entries",
        key: "till_entries",
        render: (record) => {
          return (
            <>
              <Button
                type="primary"
                onClick={async () => {
                  setTillAdding(true);
                  setSelectedDepositId(record.id);
                }}
              >
                Assign/Unassign
              </Button>
            </>
          );
        },
      },
      {
        title: "Deposit Number",
        dataIndex: "depositNumber",
      },
      {
        title: "Bag Serial No.",
        dataIndex: "bagSerialNumber",
      },
      {
        title: "Modified By",
        dataIndex: "modifiedBy",
      },
    ]);
    setTillColData([
      {
        title: "Location",
        dataIndex: "tillConfiguration",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "Till Configuration",
        dataIndex: "tillConfiguration",
        render: (record) => {
          return record?.tillIdentifier;
        },
      },
      {
        title: "Category Type",
        dataIndex: "entryType",
        render: (record) => {
          return record?.categoryType?.categoryType;
        },
      },
      {
        title: "Entry Type",
        dataIndex: "entryType",
        render: (record) => {
          return record?.entryType;
        },
      },
      {
        title: "Entry Partition",
        dataIndex: "entryPartition",
        render: (record) => {
          return record?.entryPartition;
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (record) => {
          const formatDate = new Date(record);
          return formatDate.toLocaleDateString();
        },
      },
      {
        title: "Amount",
        dataIndex: "entryAmount",
      },
    ]);
    setFormFields([
      {
        title: "Location",
        dataIndex: "locationId",
        type: "dropdown",
        require: true,
        data: allTillLocation,
        dataTitle: "name",
      },
      {
        title: "Category Type",
        dataIndex: "categoryTypeId",
        type: "dropdown",
        require: true,
        data: categoryList,
        dataTitle: "categoryType",
      },
      {
        title: "Entry Type",
        dataIndex: "entryTypeId",
        type: "dropdown",
        require: true,
        data: allEntries,
        dataTitle: "entryType",
      },
      {
        title: "Deposit Number",
        dataIndex: "depositNumber",
        type: "text",
        require: true,
      },
      {
        title: "Bag Serial No.",
        dataIndex: "bagSerialNumber",
        type: "text",
      },
    ]);
  }, [allEntries, categoryList, allTillLocation]);

  const onAddRecord = () => {
    setIsAdding(true);
    const fieldsValue = formFields.reduce((fieldValues, field) => {
      fieldValues[field.dataIndex] = "";
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setSelectedRecord(null);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  const resetTillAdding = (event) => {
    setTillAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4} style={{ display: "contents" }}>
          Deposit Slip Details
        </Typography.Title>
        <Space>
          <Form
            form={search[0]}
            onFinish={onFinish}
            autoComplete="off"
            layout={"inline"}
            style={{ justifyContent: "space-evenly" }}
          >
            <Form.Item name="date_range">
              <RangePicker />
            </Form.Item>
            {["ROLE_ADMIN", "ROLE_DEVELOPER"].includes(user?.role) && (
              <Form.Item name="location">
                <Select
                  allowClear={true}
                  style={{ width: 180 }}
                  placeholder="Select a Location"
                >
                  {allTillLocation.map((val, index) => {
                    return (
                      <Select.Option key={index} value={val.id}>
                        {val["name"]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                <SearchOutlined></SearchOutlined>Search
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={() => {
              if (allEntries.length === 0) {
                Toast.show({
                  content: "Create Entries First",
                  duration: 2000,
                });
              } else {
                onAddRecord();
              }
            }}
          >
            Add
          </Button>
        </Space>
      </div>
      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        deleteRecord={deleteDepostiSlip}
        form={form}
        formName={"deposit_slip"}
        setIsEditing={[setIsEditing]}
        editingRecord={setSelectedRecord}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getDepositSlipById}
        cookies={cookies}
      />

      <EditRecord
        title="Edit Deposit Slip"
        isEditing={isEditing}
        resetEditing={resetEditing}
        form={form}
        updateRecord={updateDepositSlip}
        editData={selectedRecord}
        formFields={formFields}
        formName={"deposit_slip"}
      />

      <AddRecord
        title="Add Deposit Slip"
        isAdding={isAdding}
        resetAdding={resetAdding}
        form={form}
        addRecord={createDepositSlip}
        formFields={formFields}
        formName={"deposit_slip"}
      />
      {tillAdding && (
        <ModalTable
          tillAdding={tillAdding}
          resetAdding={resetTillAdding}
          form={form}
          colData={tillColData}
          depositId={selectedDepositId}
          bulkAssign={setDepositSlipRequest}
          updateTableRecs={updateTillEntry}
          assignedTillEntries={getAssignedTillEntries}
          unassignedTillEntries={getUnassignedTillEntries}
          cookies={cookies}
        />
      )}
    </div>
  );
};
