import { useState, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { message } from "antd";
import { Login } from "./Pages/Login";
import { Home } from "./Pages/Home";
import { Dashboard } from "./Pages/Dashboard";
import { Category } from "./Pages/Category";
import { Report } from "./Pages/Report";
import { Entry } from "./Pages/Entry";
import { EntryPartition } from "./Pages/EntryPartition";
import { TillLocation } from "./Pages/TillLocation";
import { TillConfig } from "./Pages/TillConfig";
import { TillEntry } from "./Pages/TillEntry";
import { DepositSlip } from "./Pages/DepositSlip";
import { User } from "./Pages/User";
import { RolePrivilege } from "./Pages/RolePrivilege";
import { PageNotFound } from "./Pages/PageNotFound";
import axios from "axios";
import { getUserData } from "./services/User";
import { useCookies } from "react-cookie";

function App() {
  const [userData, setUserData] = useState({});
  const [reload, setReload] = useState(false);
  const [session, setSession, removeSession] = useCookies(["SESSION"]);
  const navigate = useNavigate();

  axios.interceptors.response.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      if (
        ["ERR_NETWORK", "ERR_BAD_REQUEST"].includes(error.code) &&
        (error.status === 401 || error.response.status === 401)
      ) {
        removeSession("SESSION");
        navigate("/login");
      }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await getUserData();
        if (user) {
          if (
            Object.keys(user).length > 0 &&
            (Object.keys(userData).length === 0 ||
              userData.rolePrivilege.role !== user.rolePrivilege.role ||
              userData.rolePrivilege.privileges.length !==
                user.rolePrivilege.privileges.length ||
              user.rolePrivilege.privileges.filter(
                (rp) => !userData.rolePrivilege.privileges.includes(rp)
              ).length > 0)
          ) {
            setUserData({
              username: user.username,
              rolePrivilege: { ...user.rolePrivilege },
              location: user.location ? user.location.id : "",
            });
          }
        } else {
          navigate("/login");
        }
      } catch (error) {
        if (error) {
          message.error("Error", error);
        }
      }
    };
    if (session.SESSION) {
      getUser();
    }
    if (reload) {
      setReload(false);
    }
  }, [userData, reload, navigate]);
  function ProtectedRoutes(props) {
    if (session.SESSION) {
      return props.children;
    } else {
      return <Navigate to="/login" />;
    }
  }
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <Home
                PageComponent={Dashboard}
                reload={setReload}
                load={reload}
                userData={userData}
              />
            </ProtectedRoutes>
          }
        />
        {["ROLE_ADMIN", "ROLE_DEVELOPER"].includes(
          userData?.rolePrivilege?.role
        ) && (
          <Route
            path="/user"
            element={
              <ProtectedRoutes>
                <Home
                  PageComponent={User}
                  reload={setReload}
                  load={reload}
                  userData={userData}
                />
              </ProtectedRoutes>
            }
          />
        )}
        {userData?.rolePrivilege?.privileges?.includes(
          "CAN_ASSIGN_ROLES_AND_PRIVILEGES"
        ) && (
          <Route
            path="/role"
            element={
              <ProtectedRoutes>
                <Home
                  PageComponent={RolePrivilege}
                  reload={setReload}
                  load={reload}
                  userData={userData}
                />
              </ProtectedRoutes>
            }
          />
        )}
        {userData?.rolePrivilege?.privileges?.includes(
          "CAN_CHANGE_CONFIGURATION"
        ) && (
          <>
            <Route
              path="/category"
              element={
                <ProtectedRoutes>
                  <Home
                    PageComponent={Category}
                    reload={setReload}
                    load={reload}
                    userData={userData}
                  />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/entry"
              element={
                <ProtectedRoutes>
                  <Home
                    PageComponent={Entry}
                    reload={setReload}
                    load={reload}
                    userData={userData}
                  />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/entry-partition"
              element={
                <ProtectedRoutes>
                  <Home
                    PageComponent={EntryPartition}
                    reload={setReload}
                    load={reload}
                    userData={userData}
                  />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/location"
              element={
                <ProtectedRoutes>
                  <Home
                    PageComponent={TillLocation}
                    reload={setReload}
                    load={reload}
                    userData={userData}
                  />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/till-config"
              element={
                <ProtectedRoutes>
                  <Home
                    PageComponent={TillConfig}
                    reload={setReload}
                    load={reload}
                    userData={userData}
                  />
                </ProtectedRoutes>
              }
            />
          </>
        )}
        <Route
          path="/till-entry"
          element={
            <ProtectedRoutes>
              <Home
                PageComponent={TillEntry}
                reload={setReload}
                load={reload}
                userData={userData}
              />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/deposit-slip"
          element={
            <ProtectedRoutes>
              <Home
                PageComponent={DepositSlip}
                reload={setReload}
                load={reload}
                userData={userData}
              />
            </ProtectedRoutes>
          }
        />
        {userData?.rolePrivilege?.privileges?.includes("CAN_VIEW_REPORTS") && (
          <Route
            path="/report"
            element={
              <ProtectedRoutes>
                <Home
                  PageComponent={Report}
                  reload={setReload}
                  load={reload}
                  userData={userData}
                />
              </ProtectedRoutes>
            }
          />
        )}
        <Route path="/login" element={<Login reload={setReload} />} />
        {userData?.rolePrivilege?.privileges && (
          <Route
            path="*"
            element={
              <ProtectedRoutes>
                <PageNotFound></PageNotFound>
              </ProtectedRoutes>
            }
          />
        )}
      </Routes>
    </>
  );
}

export default App;
