import React, { useState, useEffect } from "react";
import { Button, Typography, Form, message, Select, Space } from "antd";
import { Toast } from "antd-mobile";
import {
  createTillConfiguration,
  deleteTillConfiguration,
  getAllTillConfigurations,
  updateTillConfiguration,
  getTillConfigByLoc,
  getTillConfigurationById,
} from "../services/TillConfiguration";
import { SearchOutlined } from "@ant-design/icons";
import { getAllLocations } from "../services/TillLocation";
import { AddRecord } from "../Components/AddRecord";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";
export const TillConfig = ({ userInfo }) => {
  const [colData, setColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const search = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [allTillLocation, setAllTillLocation] = useState([]);
  const [searchLocation, setSearchLocation] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    const { location } = values;

    try {
      let res;
      if (location !== undefined) {
        res = await getTillConfigByLoc(location);
      } else {
        res = await getAllTillConfigurations();
      }
      if (res?.status === 200) {
        setDataSource(res.data);
      }
    } catch (error) {
      if (error.code !== "ERR_NETWORK") {
        message.error(error.message);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        const res = await getAllTillConfigurations();

        if (res?.status === 200) {
          setDataSource(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllLocations();
        if (res?.status === 200) {
          setAllTillLocation(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      setColData([
        {
          title: "Location",
          dataIndex: "location",
          render: (record) => {
            return record?.name;
          },
        },
        {
          title: "Name",
          dataIndex: "tillIdentifier",
        },
        {
          title: "Modified By",
          dataIndex: "modifiedBy",
        },
      ]);

      setReload(false);
      setLoading(false);
    };
    getData();
  }, [reload]);
  useEffect(() => {
    setFormFields([
      {
        title: "Location",
        dataIndex: "locationId",
        type: "dropdown",
        require: true,
        data: allTillLocation,
        dataTitle: "name",
      },
      {
        title: "Name",
        dataIndex: "tillIdentifier",
        type: "text",
        require: false,
      },
    ]);
  }, [allTillLocation]);

  const onAddRecord = () => {
    setIsAdding(true);
    const fieldsValue = formFields.reduce((fieldValues, field) => {
      if (field.dataIndex === "locationId" && searchLocation !== null) {
        fieldValues[field.dataIndex] = searchLocation;
      } else {
        fieldValues[field.dataIndex] = "";
      }
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setSelectedRecord(null);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4}>
          Till Configuration Details
        </Typography.Title>
        <Space>
          <Form
            form={search.form}
            onFinish={onFinish}
            autoComplete="off"
            layout={"inline"}
            style={{ justifyContent: "space-evenly" }}
          >
            <Form.Item name="location">
              <Select
                allowClear={true}
                style={{ width: 180 }}
                placeholder="Select a Location"
                onChange={(e) => setSearchLocation(e ? e : null)}
              >
                {allTillLocation.map((val, index) => {
                  return (
                    <Select.Option key={index} value={val.id}>
                      {val["name"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <SearchOutlined></SearchOutlined>Search
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={() => {
              if (allTillLocation.length === 0) {
                Toast.show({
                  content: "Create Till Locations First",
                  duration: 2000,
                });
              } else {
                onAddRecord();
              }
            }}
          >
            Add
          </Button>
        </Space>
      </div>
      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        deleteRecord={deleteTillConfiguration}
        form={form}
        formName={"till_config"}
        setIsEditing={[setIsEditing]}
        editingRecord={setSelectedRecord}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getTillConfigurationById}
      />

      <EditRecord
        title="Edit Till Configuration"
        isEditing={isEditing}
        resetEditing={resetEditing}
        form={form}
        updateRecord={updateTillConfiguration}
        editData={selectedRecord}
        formFields={formFields}
      />
      <AddRecord
        title="Add Till Configuration"
        isAdding={isAdding}
        resetAdding={resetAdding}
        form={form}
        addRecord={createTillConfiguration}
        formFields={formFields}
        formName={"till_config"}
      />
    </div>
  );
};
