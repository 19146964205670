import React, { useState, useEffect } from "react";
import { Button, Typography, Form, message } from "antd";
import { Toast } from "antd-mobile";
import {
  createEntry,
  deleteEntry,
  getAllEntries,
  updateEntry,
  getEntryById,
} from "../services/Entry";
import { getAllCategories } from "../services/Category";
import { AddRecord } from "../Components/AddRecord";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";
export const Entry = ({ userInfo }) => {
  const [colData, setColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        const res = await getAllEntries();
        if (res?.status === 200) {
          setDataSource(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      try {
        const res = await getAllCategories();
        if (res?.status === 200) {
          setAllCategories(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }
      setColData([
        {
          title: "Category Type",
          dataIndex: "categoryType",
          render: (record) => {
            return record?.categoryType;
          },
        },
        {
          title: "Entry Type",
          dataIndex: "entryType",
        },
        {
          title: "Slip Deposited",
          dataIndex: "hasDepositSlip",
          render: (record) => {
            return record ? "Yes" : "No";
          },
        },
        {
          title: "Partition",
          dataIndex: "hasPartition",
          render: (record) => {
            return record ? "Yes" : "No";
          },
        },
        {
          title: "Cheque",
          dataIndex: "hasCheque",
          render: (record) => {
            return record ? "Yes" : "No";
          },
        },
        {
          title: "Modified By",
          dataIndex: "modifiedBy",
        },
      ]);

      setLoading(false);
      setReload(false);
    };
    getData();
  }, [reload]);
  useEffect(() => {
    setFormFields([
      {
        title: "Category Type",
        dataIndex: "categoryTypeId",
        type: "dropdown",
        require: true,
        data: allCategories,
        dataTitle: "categoryType",
      },
      {
        title: "Entry Type",
        dataIndex: "entryType",
        type: "text",
        require: true,
      },
      {
        title: "Slip Deposited",
        dataIndex: "hasDepositSlip",
        type: "radio",
        require: true,
      },
      {
        title: "Partition",
        dataIndex: "hasPartition",
        type: "radio",
        require: true,
      },
      {
        title: "Cheque",
        dataIndex: "hasCheque",
        type: "radio",
        require: true,
      },
    ]);
  }, [allCategories]);

  const onAddRecord = () => {
    setIsAdding(true);
    const fieldsValue = formFields.reduce((fieldValues, field) => {
      fieldValues[field.dataIndex] = "";
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setSelectedRecord(null);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };

  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4}>Entry Type Details</Typography.Title>
        <Button
          type="primary"
          onClick={() => {
            if (allCategories.length === 0) {
              Toast.show({
                content: "Create Categories First",
                duration: 2000,
              });
            } else {
              onAddRecord();
            }
          }}
        >
          Add
        </Button>
      </div>
      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        deleteRecord={deleteEntry}
        form={form}
        formName={"entry"}
        setIsEditing={[setIsEditing]}
        editingRecord={setSelectedRecord}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getEntryById}
      />

      <EditRecord
        title="Edit Entry"
        isEditing={isEditing}
        resetEditing={resetEditing}
        form={form}
        updateRecord={updateEntry}
        editData={selectedRecord}
        formFields={formFields}
      />
      <AddRecord
        title="Add Entry"
        isAdding={isAdding}
        resetAdding={resetAdding}
        form={form}
        addRecord={createEntry}
        formFields={formFields}
        formName={"entry"}
      />
    </div>
  );
};
