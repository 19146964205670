import React, { useState, useEffect } from "react";
import { Button, Typography, Form, message } from "antd";
import {
  createLocation,
  deleteLocation,
  getAllLocations,
  updateLocation,
  getLocationById,
} from "../services/TillLocation";
import { AddRecord } from "../Components/AddRecord";
import { EditRecord } from "../Components/EditRecord";
import { TableView } from "../Components/TableView";
export const TillLocation = ({ userInfo }) => {
  const [colData, setColData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        const res = await getAllLocations();
        if (res?.status === 200) {
          setDataSource(res.data);
        }
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          message.error(error.message);
        }
      }

      setColData([
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Region",
          dataIndex: "region",
        },
        {
          title: "Modified By",
          dataIndex: "modifiedBy",
        },
      ]);
      setFormFields([
        {
          title: "Name",
          dataIndex: "name",
          type: "text",
          require: true,
        },
        {
          title: "Region",
          dataIndex: "region",
          type: "text",
          require: true,
        },
      ]);
      setReload(false);
      setLoading(false);
    };

    getData();
  }, [reload]);

  const onAddRecord = () => {
    setIsAdding(true);
    const fieldsValue = formFields.reduce((fieldValues, field) => {
      fieldValues[field.dataIndex] = "";
      return fieldValues;
    }, {});
    form.setFieldsValue(fieldsValue);
  };
  const resetEditing = (event) => {
    setIsEditing(false);
    setSelectedRecord(null);
    if (event !== "cancel") {
      setReload(true);
    }
  };
  const resetAdding = (event) => {
    setIsAdding(false);
    if (event !== "cancel") {
      setReload(true);
    }
  };

  return (
    <div className="TContent">
      <div className="THeader">
        <Typography.Title level={4}>Location Details</Typography.Title>
        <Button
          type="primary"
          onClick={() => {
            onAddRecord();
          }}
        >
          Add
        </Button>
      </div>

      <TableView
        loading={loading}
        dataSource={dataSource}
        rkey="id"
        columnsData={colData}
        deleteRecord={deleteLocation}
        form={form}
        formName={"location"}
        setIsEditing={[setIsEditing]}
        editingRecord={setSelectedRecord}
        setReload={setReload}
        formFields={formFields}
        editEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_EDIT_ENTRIES"
        )}
        delEnable={userInfo?.rolePrivilege?.privileges?.includes(
          "CAN_DELETE_ENTRIES"
        )}
        getSelectedRecord={getLocationById}
      />

      <EditRecord
        title="Edit Location"
        isEditing={isEditing}
        resetEditing={resetEditing}
        form={form}
        updateRecord={updateLocation}
        editData={selectedRecord}
        formFields={formFields}
      />
      <AddRecord
        title="Add Location"
        isAdding={isAdding}
        resetAdding={resetAdding}
        form={form}
        addRecord={createLocation}
        formFields={formFields}
        formName={"location"}
      />
    </div>
  );
};
